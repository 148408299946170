<template>
  <div>
    <div class="row">
      <div class="col-lg-6">
        <card>
          <form class="form-horizontal">
            <h4 slot="header" class="card-title">
              Add Retailer
              <p v-if="false && !maxReached" class="category">You can add up to {{ itemSlotsRemaining }} more Retailers on your PLACEHOLDER Plan.</p>
            </h4>
            <div class="row">
              <label class="col-md-3 col-form-label">Image</label>
              <div class="col-md-9">
                <div style="width: 200px;">
                  <single-image-uploader @tempFileIdUpdated="setTempImageId" />
                </div>
              </div>

              <label class="col-md-3 col-form-label">Name</label>
              <div class="col-md-9">
                <fg-input v-model="newItem.name" maxlength="15"></fg-input>
              </div>
            </div>

            <div class="card-footer text-right">
              <p-button v-if="!loading" type="primary" round @click.prevent="addSalesChannel()">Submit</p-button>
              <p-button v-if="loading" type="primary" round disabled="disabled">
                <i class="fa fa-circle-o-notch fa-spin"></i>
                Submit
              </p-button>
            </div>

          </form>
        </card> <!-- end card -->
      </div> <!-- end col-md-12 -->
    </div>

    <div class="row">
      <div v-for="(salesChannel, index) in items" class="col-lg-3" :key="salesChannel.id">
        <div class="card" style="padding-top:10px;">
          <div class="text-center">
            <h3>{{salesChannel.name}}</h3>
          </div>
          <div class="text-center" style="height: 140px; display: flex; align-items: center;">
            <div class="img-container">
              <img v-if="salesChannel.logo_img" :src="salesChannel.logo_img" :alt="salesChannel.name">
              <span v-if="!salesChannel.logo_img">{NO RETAILER LOGO}</span>
            </div>
          </div>
          <hr>
          <div class="text-center" style="height: 40px;">
            <p-button type="primary" round size="sm" v-if="!salesChannel.deleting" @click.prevent="deleteSalesChannel(salesChannel, index)">Delete</p-button>
            <p-button type="primary" round size="sm" v-if="salesChannel.deleting" disabled="disabled">
              <i class="fa fa-circle-o-notch fa-spin"></i>
              Deleting...
            </p-button>

            <p-button type="primary" round size="sm" v-if="salesChannel.isArchived && !salesChannel.unarchiving" @click.prevent="uncarchiveSalesChannel(salesChannel)">Un-Archive</p-button>
            <p-button type="primary" round size="sm" v-if="salesChannel.unarchiving" disabled="disabled">
              <i class="fa fa-circle-o-notch fa-spin"></i>
              Un-Archiving...
            </p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="false">
      <div class="col-md-4 pull-right">
        <div style="float: right;">
          <p class="category">Show Archived Retailers</p>
          <p-switch v-model="showArchived" type="primary" on-text="ON" off-text="OFF"></p-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters, mapActions } from 'vuex'
  import SalesChannelService from 'src/services/salesChannel.service'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import SingleImageUploader from 'src/components/SharedComponents/SingleImageUploader.vue'

  export default{
    components: {
      PSwitch,
      SingleImageUploader
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      maxReached () {
        return false
      },
      itemSlotsRemaining () {
        return 5
      }
    },
    mixins: [NotifyMixin],
    data () {
      return {
        items: [],
        newItem: {
          name: '',
          imgTempFileId: ''
        },
        loading: false,
        showArchived: false
      }
    },
    methods: {
      async init () {
        let response = await SalesChannelService.index(this.user.options.active_brand_id, 'account')
        this.items = response.data
      },
      setTempImageId (newId) {
        this.newItem.imgTempFileId = newId
      },
      async addSalesChannel () {
        try {
          this.loading = true
          await SalesChannelService.create(this.user.options.active_brand_id, this.newItem)
          this.showNotify('Retailer created', 'success', 'Success')
          this.loading = false
          this.init()
        } catch (e) {
          this.showNotify(e.response.data.message, 'danger', 'Failed')
          this.loading = false
        }
      },
      async deleteSalesChannel (salesChannel, index) {
        console.log('deleting', this.user.options.active_brand_id, salesChannel.id)
        try {
          salesChannel.deleting = true
          Vue.set(this.items, index, salesChannel)
          await SalesChannelService.destroy(this.user.options.active_brand_id, salesChannel.id)
          this.showNotify('Retailer deleted', 'success', 'Success')
          this.init()
        } catch (e) {
          this.showNotify(e.response.data.message, 'danger', 'Failed')
          salesChannel.deleting = false
          Vue.set(this.items, index, salesChannel)
        }
      },
      uncarchiveSalesChannel (salesChannel) {
        // nothing
      },
      selectFile (event) {
        this.newItem.logoImgFile = event.target.files[0]
      },
      isDeleting (item) {
        return item.deleting
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      }
    },
    watch: {
      showArchived: function (newFrom, oldFrom) {
        // nothing
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
.img-container {
  max-width: 140px;
  max-height: 140px;
  margin: auto;
  text-align: center;
}
.img-container img {
  max-width: 150px;
  max-height: 100px;
}
</style>
