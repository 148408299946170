import Api from 'src/apis/api'

class AuthCodeBatchService {
  index (brandId) {
    return Api.get('/api/brands/' + brandId + '/auth_code_batches?with_products=true')
  }

  show (brandId, authCodeBatchId) {
    return Api.get('/api/brands/' + brandId + '/auth_code_batches/' + authCodeBatchId)
  }

  indexNotComplete (brandId) {
    return Api.get('/api/brands/' + brandId + '/auth_code_batches?not_complete=true&with_products=true&with_last_printed=true')
  }

  create (brandId, qty, productId = null, salesChannelId = null, batchNum = null) {
    let data = {
      qty: qty
    }
    
    if (productId) data.product_id = productId
    if (salesChannelId) data.sales_channel_id = salesChannelId
    if (batchNum) data.batch_num = batchNum

    return Api({
      method: 'post',
      url: '/api/brands/' + brandId + '/auth_code_batches',
      data: data
    })
  }

  setAsPrinted (brandId, authCodeBatchId) {
    return Api({
      method: 'put',
      url: '/api/brands/' + brandId + '/auth_code_batches/' + authCodeBatchId,
      data: { status: 'printed' }
    })
  }

  // disables printing authcodes and marks 'Complete'
  close (brandId, authCodeBatchId) {
    return Api({
      method: 'put',
      url: '/api/brands/' + brandId + '/auth_code_batches/' + authCodeBatchId,
      data: { status: 'complete' }
    })
  }
}

export default new AuthCodeBatchService()
