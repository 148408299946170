<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title">Business Details</h4>
    </div>
    <div class="card-body">
      <form>
        <div class="row">
          <div class="col-md-6">
            <fg-input type="text"
                      label="Company"
                      placeholder="My Company, INC"
                      v-model="account.company">
            </fg-input>
          </div>
          <div class="col-md-6">
            <fg-input type="text"
                      label="Country"
                      placeholder="Country"
                      v-model="account.country">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input type="text"
                      label="Address Line 1"
                      placeholder="123 Main Street"
                      v-model="account.address1">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <fg-input type="text"
                      label="Address Line 2"
                      placeholder="Suite 105"
                      v-model="account.address2">
            </fg-input>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4">
            <fg-input type="text"
                      label="City"
                      placeholder="Santa Barbara"
                      v-model="account.city">
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input type="text"
                      label="State"
                      placeholder="California"
                      v-model="account.state">
            </fg-input>
          </div>
          <div class="col-md-4">
            <fg-input type="text"
                      label="Postal Code"
                      placeholder="93105"
                      v-model="account.zip">
            </fg-input>
          </div>
        </div>

        <div class="text-center">
          <p-button v-if="!loading" type="info" round @click.prevent="saveAccount()">Submit</p-button>
          <p-button v-if="loading" type="info" round disabled="disabled">
            <i class="fa fa-circle-o-notch fa-spin"></i>
            Submit
          </p-button>
        </div>
        <div class="clearfix"></div>
      </form>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import AccountService from 'src/services/account.service'

  export default {
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    mixins: [NotifyMixin],
    data () {
      return {
        account: {
          company: '',
          username: '',
          email: '',
          lastName: '',
          address: '',
          address2: '',
          city: '',
          state: '',
          zip: ''
        },
        loading: true
      }
    },
    methods: {
      async init () {
        await this.getAccount()
        this.loading = false
      },
      async getAccount () {
        let response = await AccountService.get(this.user.account.id)
        this.account = response.data
      },
      async saveAccount () {
        this.loading = true
        await AccountService.update(this.user.account.id, this.account)
        this.loading = false
        this.showNotify('Account details saved.', 'success', 'Success')
      },
      updateProfile () {
        alert('Your data: ' + JSON.stringify(this.user))
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }

</script>
<style>

</style>
