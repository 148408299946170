<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row justify-content-center">
        <div class="col-sm-10">
          <h3 class="info-text">Register</h3>
          <p> If you have an invite code you are welcome to register now, otherwise please <a href="http://localhost:8000/login#/login">join the waiting list</a>. </p>
        </div>
        <div class="col-sm-5">
          <ValidationProvider
            name="inviteCode"
            rules="required"
            v-slot="{ passed, failed }"
          >
          <fg-input v-model="form.inviteCode"
                    type="text"
                    :error="failed ? 'The Invite code field is required': null"
                    :hasSuccess="passed"
                    name="inviteCode"
                    @blur="checkInviteCode" addon-left-icon="nc-icon nc-lock-circle-open" placeholder="Invite code...">
          </fg-input>
          </ValidationProvider>
          <ValidationProvider
            name="name"
            rules="required"
            v-slot="{ passed, failed }"
          >
          <fg-input v-model="form.name"
                    type="text"
                    :error="failed ? 'The Name field is required': null"
                    :hasSuccess="passed"
                    name="name"
                    addon-left-icon="nc-icon nc-single-02" placeholder="Name..."></fg-input>
          </ValidationProvider>
          <ValidationProvider
            name="email"
            rules="required|email"
            v-slot="{ passed, failed }"
          >
          <fg-input v-model="form.email"
                    type="email"
                    :error="failed ? 'The Email field is required': null"
                    :hasSuccess="passed"
                    name="email"
                    addon-left-icon="nc-icon nc-email-85" placeholder="Email...">
          </fg-input>
          </ValidationProvider>
        </div>
        <div class="col-sm-5">
          <ValidationProvider
            name="password"
            rules="required"
            v-slot="{ passed, failed }"
          >
          <fg-input v-model="form.password"
                    type="password"
                    :error="failed ? 'Password must contain at least 6 chars, 1 capital, 1 lowercase, 1 number': null"
                    :hasSuccess="passed"
                    name="password"
                    addon-left-icon="nc-icon nc-key-25" placeholder="Password...">
          </fg-input>
          </ValidationProvider>
          <ValidationProvider
            name="password_confirmation"
            rules="required"
            v-slot="{ passed, failed }"
          >
          <fg-input v-model="form.password_confirmation"
                    type="password"
                    :error="failed ? 'Password must contain at least 6 chars, 1 capital, 1 lowercase, 1 number': null"
                    :hasSuccess="passed"
                    name="password_confirmation"
                    addon-left-icon="nc-icon nc-key-25" placeholder="Confirm Password...">
          </fg-input>
          </ValidationProvider>

          <p-checkbox class="text-left" name="terms" v-model="form.acceptTerms">
            I agree to the
            <a href="https://www.authentic.net/terms" target="_blank">terms and conditions</a>.
          </p-checkbox>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import {Upload} from 'element-ui';
  import { extend } from "vee-validate"
  import { required, email } from "vee-validate/dist/rules"
  import InviteCodeService from 'src/services/inviteCode.service'
  import RegistrationService from 'src/services/registration.service'
  import { NotifyMixin } from 'src/mixins/notify.mixin'

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      [Upload.name]: Upload
    },
    props: {
      wizardModel: {
        type: Object,
        required: false,
        default: null
      }
    },
    mixins: [NotifyMixin],
    data() {
      return {
        form: {
          name: '',
          inviteCode: '',
          email: '',
          password: '',
          password_confirmation: '',
          acceptTerms: true,
          accountId: ''
        },
        inviteCodeIsValid: false
      };
    },
    methods: {
      init () {
        console.log('initializing')
        if (this.inputsDisabled) {
          this.form.name = this.wizardModel.name
          this.form.email = this.wizardModel.email
          this.form.inviteCode = 'n/a'
        }
      },
      async checkInviteCode () {
        try {
          let response = await InviteCodeService.show(this.form.inviteCode)
          console.log('response data', response.data)
          if (response.data.qty_used < response.data.qty) {
            this.inviteCodeIsValid = true
            if (response.data.name) this.form.name = response.data.name
            if (response.data.email) this.form.email = response.data.email
          } else {
            this.inviteCodeIsValid = false
            this.showNotify('Sorry, this invite code has already been used or is invalid.', 'danger')
          }
        } catch (e) {
          this.inviteCodeIsValid = false
          this.showNotify('Sorry, this invite code was not found.', 'danger')
        }
      },
      async register () {
        console.log('register')
        try {
          await RegistrationService.registerViaCode(this.form.inviteCode, this.form.name, this.form.email, this.form.password)
          this.$router.replace({ name: 'Login' })
        } catch (e) {
          console.log(e)
          this.showNotify(e.response.data.message, 'danger', 'Failed')
        }
      },
      validate() {
        if (this.inputsDisabled) {
          this.$emit('on-validated', true, this.wizardModel)
        } else {
          if (!this.inviteCodeIsValid) {
            this.showNotify('Sorry, this invite code is invalid.', 'danger')
            return
          }
          if (!this.form.acceptTerms) {
            this.showNotify('You must accept the terms to continue.', 'danger')
            return
          }
          return this.$refs.form.validate().then(async res => {
            try {
              let result = await RegistrationService.createAccount(this.form.inviteCode, this.form.name, this.form.email, this.form.password)
              this.form.accountId = result.data.account.id
              this.$emit('on-validated', res, this.form)
              return res
            } catch (e) {
              console.log(e)
              if (e.response.data.errors.password && e.response.data.errors.password == 'The password format is invalid.') {
                this.showNotify('Your password must be at least 6 chars and contain at least 1 lowercase letter, 1 uppercase letter, 1 number and 1 symbol.', 'danger', 'Failed')
              } else {
                this.showNotify(e.response.data.message, 'danger', 'Failed')
              }
            }
          })
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style lang="scss">
  .card-wizard .picture-container .picture {
    input[type="file"] {
      display: none;
    }
  }
</style>
