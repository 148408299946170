<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <h3 class="info-text">Add Brand Logo &amp; Name</h3>
        </div>
        
        <div class="col-lg-10">
          <div style="width: 200px;">
            <single-image-uploader @tempFileIdUpdated="setTempImageId" />
          </div>
          <ValidationProvider
            name="brandName"
            rules="required"
            v-slot="{ passed, failed }"
          >
          <fg-input v-model="newBrand.brandName"
                    type="text"
                    :error="failed ? 'The Brand name is required': null"
                    :hasSuccess="passed"
                    name="brandName"
                    placeholder="Brand Name...">
          </fg-input>
          </ValidationProvider>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import {Select, Option} from 'element-ui'
  import { extend } from "vee-validate"
  import { required, email } from "vee-validate/dist/rules"
  import SingleImageUploader from 'src/components/SharedComponents/SingleImageUploader.vue'
  import { NotifyMixin } from 'src/mixins/notify.mixin'

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      SingleImageUploader
    },
    mixins: [NotifyMixin],
    data() {
      return {
        newBrand: {
          brandName: '',
          imgTempFileId: ''
        }
      };
    },
    methods: {
      validate() {
        if (this.newBrand.imgTempFileId == '') {
          this.showNotify('Please provide a brand logo.', 'danger')
          return;
        }
        return this.$refs.form.validate().then(res => {
          if (!res) {
            return;
          }
          this.$emit("on-validated", res, this.newBrand);
          return res;
        });
      },
      setTempImageId (newId) {
        this.newBrand.imgTempFileId = newId
      }
    }

  }
</script>
<style>
</style>
