import Api from 'src/apis/api'

class RegistrationService {
  registerViaCode (inviteCode, name, email, password) {
    return Api({
      method: 'post',
      url: '/api/register-via-code/',
      data: { invite_code: inviteCode, name: name, email: email, password: password, password_confirmation: password }
    })
  }

  createAccount (inviteCode, name, email, password) {
    return Api.post('/api/registration/create-account', { invite_code: inviteCode, name: name, email: email, password: password, password_confirmation: password })
  }

  createStripeSetupIntent (accountId) {
    return Api.post('/api/registration/account/' + accountId + '/stripe-setup-intent')
  }

  createSubscription (accountId, planId, paymentMethodId, brandName, brandTempImgId) {
    var data = {
      payment_method_id: paymentMethodId,
      plan_id: planId,
      brand_name: brandName,
      img_temp_file_id: brandTempImgId
    }
    
    return Api.post('/api/registration/account/' + accountId + '/create-subscription', data)
  }
}

export default new RegistrationService()
