import Api from 'src/apis/api'

class ProductTypeService {
  index (brandId, page = 1, search = '') {
    // return axios({
    //   method: 'get',
    //   url: '/api/account/product_types',
    //   data: { brand_id: 1 }
    // })
    return Api.get('/api/brands/' + brandId + '/product_types?page=' + page + '&search=' + search)
  }

  // get (id) {
  //   return axios({
  //     method: 'get',
  //     url: '/api/account/brand',
  //     data: { id: id }
  //   })
  // }

  create (brandId, productTypeData) {
    const data = new FormData()
    data.append('name', productTypeData.name)
    data.append('redirect_url', productTypeData.redirect_url)
    data.append('img_temp_file_id', productTypeData.imgTempFileId)
    data.append('variations', JSON.stringify(productTypeData.variations))
    return Api.post('/api/brands/' + brandId + '/product_types', data)
  }

  update (brandId, productTypeData) {
    const data = new FormData()
    data.append('name', productTypeData.name)
    if (productTypeData.redirect_url) data.append('redirect_url', productTypeData.redirect_url)
    data.append('imgFile', productTypeData.imgFile)
    data.append('products', JSON.stringify(productTypeData.products))
    return Api.post('/api/brands/' + brandId + '/product_types/' + productTypeData.id + '?_method=PUT', data)
  }

  // destroy (id) {
  //   return axios({
  //     method: 'delete',
  //     url: '/api/account/brands',
  //     data: { id: id }
  //   })
  // }
}

export default new ProductTypeService()
