<template>
  <div>
    <div style="text-align: center; margin-top: 40px;">
      <h2>Authentic.net - Scan In</h2>
      <p>Quantity Assigned: {{ qtyAssigned }}</p>
    </div>
    <div class="col-md-4 ml-auto mr-auto">
      <qrcode-stream @decode="onDecode"></qrcode-stream>
      <div class="text-center" style="padding-top:20px;">
        <p>*Please use a mobile device to scan-in un-assigned Authentic.net codes.</p>
        <router-link :to="{ name: 'Batch Scan In' }" class="btn">
          Back
        </router-link>
        <div style="margin-top: 20px;">
          <h5>{{ product.product_type.name }}</h5>
          <div>
            <strong>SKU:</strong> {{product.sku}}
          </div>
          <div v-for="(option_assignment, opt_index) in product.option_assignments" :key="product.id + '-' + option_assignment.option.name">
            <strong>{{ option_assignment.option.name }}:</strong> {{ option_assignment.option_value.name }}
          </div>
          <div v-for="(fnsku, fnsku_index) in product.fnsku_assignments" :key="product.id + '-fnsku-' + fnsku_index">
            <strong>{{ fnsku.marketplace }}:</strong> {{ fnsku.fnsku }}
          </div>
          <img v-if="product.product_type.img" :src="product.product_type.img" :alt="product.product_type.name" style="max-width: 200px;">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { QrcodeStream } from 'vue-qrcode-reader'
import ProductService from 'src/services/product.service'
import AuthCodeService from 'src/services/authCode.service'
import { NotifyMixin } from 'src/mixins/notify.mixin'

export default {
  mixins: [NotifyMixin],
  computed: {
    ...mapGetters({
      user: 'auth/user'
    })
  },
  data () {
    return {
      data: {
        productId: '',
        retailerId: '',
        batchNum: ''
      },
      product: {
        product_type: {},
        option_assignments: [],
        fnsku_assignments: []
      },
      qtyAssigned: 0,
      scannedCodes: []
    }
  },
  components: { QrcodeStream },
  methods: {
    init () {
      this.data.productId = this.$route.params.productId
      this.data.retailerId = this.$route.params.retailerId
      this.data.batchNum = this.$route.params.batchNum
      console.log('productId, retailerId, batchNum', this.data)
      this.loadProduct()
    },
    async loadProduct () {
      let response = await ProductService.get(this.user.options.active_brand_id, this.data.productId)
      console.log('response', response)
      this.product = response.data
    },
    onDecode (content) {
      console.log('on decode: ' + content)
      let code = ''
      // let params = new URLSearchParams(content)
      if (content.includes('scan.authentic.net')) {
        let x = content.split('/')
        code = x[x.length - 1]
        console.log(code)
        // this.$router.push({ name: 'Authenticate', params: { code: code } })
      } else if (content.includes('authentic.net')) {
        let x = content.split('=')
        code = x[x.length - 1]
        console.log(code)
        // this.$router.push({ name: 'Authenticate', params: { code: code } })
      } else {
        let x = content.split('=')
        code = x[x.length - 1]
        console.log(code)
      }
      if (this.scannedCodes.includes(code)) {
        this.showNotify('This code was already assigned.', 'danger', 'Failed')
      } else {
        this.assignCode(code)
      }
    },
    onLocate (points) {
      // console.log('on locate: ' + points)
    },
    async assignCode (code) {
      console.log('assigning code', code)
      try {
        await AuthCodeService.update(this.user.options.active_brand_id, code, this.data.productId, this.data.retailerId, this.data.batchNum)
        this.showNotify('This code was successfully assigned.', 'success', 'Success')
        this.qtyAssigned++
        this.scannedCodes.push(code)
      }  catch (e) {
        this.showNotify(e.response.data.message, 'danger', 'Failed')
      }
    }
  },
  mounted: function () {
    this.$nextTick(function () {
      this.init()
    })
  }
}
</script>

<style>

</style>