<template>
  <card style="max-width: 1400px;">
    <form v-if="initialized" class="form-horizontal">
      <h4 slot="header" class="card-title">
        Edit Product {{ updatedProdType.name }}
      </h4>
      <div class="row">
        <label class="col-md-3 col-form-label">Name</label>
        <div class="col-md-9">
          <fg-input v-model="updatedProdType.name"></fg-input>
        </div>

        <label class="col-md-3 col-form-label">Redirect URL</label>
        <div class="col-md-9">
          <fg-input v-model="updatedProdType.redirect_url"></fg-input>
        </div>

        <label class="col-md-3 col-form-label">Image</label>
        <div class="col-md-9">
          <input type="file" name="imgFile" ref="imgFile" accept="image/*" @change="selectFile" />
        </div>
      </div>

      <div class="row">
        <label class="col-sm-2 col-form-label">Variation (SKU, UPC)</label>
        <div class="col-sm-10">
          <fg-input placeholder="SKU" v-model="updatedProdType.products[0].sku"></fg-input>
          <fg-input placeholder="UPC" v-model="updatedProdType.products[0].upc"></fg-input>
          <div class="row" v-for="(option, option_index) in updatedProdType.products[0].option_assignments" :key="'option-' + option_index">
            <label class="col-sm-2 col-form-label">Attribute (optional)</label>
            <div class="col-md-5">
              <fg-input>
                <el-select class="select-info"
                          size="large"
                          placeholder="select..."
                          v-model="updatedProdType.products[0].option_assignments[option_index].option.name"
                          @input="changeAttributeName($event, option_index)">
                  <el-option v-for="option in attributeNames"
                            class="select-danger"
                            :value="option"
                            :label="option"
                            :key="option">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-md-5">
              <fg-input
                placeholder="Value (ex: Medium, Blue, Adult)"
                v-model="updatedProdType.products[0].option_assignments[option_index].option_value.name"
                @blur="checkVariationOptions(0, option_index)">
              </fg-input>
            </div>
          </div>

          <div class="row" v-for="(fnsku, fnsku_index) in updatedProdType.products[0].fnsku_assignments" :key="'fnsku-' + fnsku_index">
            <label class="col-sm-2 col-form-label">Amazon FNSKU (optional)</label>
            <div class="col-sm-5">
              <fg-input>
                <el-select class="select-info"
                          size="large"
                          placeholder="Amazon Marketplace"
                          v-model="updatedProdType.products[0].fnsku_assignments[fnsku_index].marketplace">
                  <el-option v-for="option in amzMarketplaces"
                            class="select-danger"
                            :value="option"
                            :label="option"
                            :key="option">
                  </el-option>
                </el-select>
              </fg-input>
            </div>
            <div class="col-sm-5">
              <fg-input
                placeholder="FNSKU (ex: B000F453)"
                v-model="updatedProdType.products[0].fnsku_assignments[fnsku_index].fnsku"
                @blur="checkVariationFnskus(0, fnsku_index)">
              </fg-input>
            </div>
          </div>
        </div>
      </div>

      <div slot="footer" class="row">
        <div class="col-md-12">

          <p-button v-if="!loading" type="info" round @click.prevent="submitUpdate()">Submit</p-button>
          <p-button v-if="loading" type="info" round disabled="disabled">
            <i class="fa fa-circle-o-notch fa-spin"></i>
            Submit
          </p-button>
          <p-button v-if="!loading" type="info" round @click.prevent="cancel()">Cancel</p-button>

        </div>
      </div>
    </form>
  </card> <!-- end card -->
</template>
<script>
  import ProductTypeService from 'src/services/productType.service'
  import { NotifyMixin } from 'src/mixins/notify.mixin'

  export default {
    props: {
      user: {
        type: Object,
        required: true
      },
      brand: {
        type: Object,
        required: true
      },
      productType: {
        type: Object,
        required: true
      },
      product: {
        type: Object,
        required: true
      }
    },
    mixins: [NotifyMixin],
    data () {
      return {
        initialized: false,
        loading: false,
        updatedProdType: null,
        updatedProd: null,
        amzMarketplaces: [
          'Amazon.com',
          'Amazon.ca',
          'Amazon.com.mx',
          'Amazon.com.br',
          'Amazon.cn',
          'Amazon.in',
          'Amazon.jp',
          'Amazon.sg',
          'Amazon.com.tr',
          'Amazon.ae',
          'Amazon.sa',
          'Amazon.fr',
          'Amazon.de',
          'Amazon.it',
          'Amazon.nl',
          'Amazon.es',
          'Amazon.se',
          'Amazon.co.uk',
          'Amazon.com.au'
        ],
        attributeNames: [
          'color',
          'size',
          'style',
          'design',
          'length',
          'width',
          'height',
          'weight',
          'scent',
          'flavor',
          'quantity',
          'version',
          'signature',
          'other'
        ]
      }
    },
    methods: {
      init () {
        this.initializeUpdatedProdType()
        this.initialized = true
      },
      initializeUpdatedProdType () {
        this.updatedProdType = JSON.parse(JSON.stringify(this.productType))
        var prod = JSON.parse(JSON.stringify(this.product))
        this.updatedProdType.imgFile = ''

        prod.option_assignments.push({
          option: { name: '' },
          option_value: { name: ''}
        })
        prod.fnsku_assignments.push({
          marketplace: '',
          fnsku: ''
        })
        this.updatedProdType.products = []
        this.updatedProdType.products.push(prod)
      },
      async submitUpdate () {
        this.loading = true
        try {
          await ProductTypeService.update(this.brand.id, this.updatedProdType)
          this.$emit("prodUpdated", true)
          this.loading = false
        } catch (e) {
          this.showNotify('There was an error updating this product.', 'danger', 'Failed')
          this.loading = false
        }
      },
      selectFile (event) {
        this.updatedProdType.imgFile = event.target.files[0]
      },
      checkVariationOptions (variationIndex, optionIndex) {
        if (this.updatedProdType.products[variationIndex].option_assignments[optionIndex] !== undefined) {
          this.updatedProdType.products[variationIndex].option_assignments[optionIndex].option.name = this.updatedProdType.products[variationIndex].option_assignments[optionIndex].option.name.trim()
          this.updatedProdType.products[variationIndex].option_assignments[optionIndex].option_value.name = this.updatedProdType.products[variationIndex].option_assignments[optionIndex].option_value.name.trim()
        } else {
          this.updatedProdType.products[variationIndex].option_assignments[optionIndex].option.name = ''
          this.updatedProdType.products[variationIndex].option_assignments[optionIndex].option_value.name = ''
        }

        if (this.updatedProdType.products[variationIndex].option_assignments[optionIndex].option.name.trim() === '' &&
            this.updatedProdType.products[variationIndex].option_assignments[optionIndex].option_value.name.trim() === '') {
          // if there is only 1 option field or if this is the last one, then leave it be
          if (this.updatedProdType.products[variationIndex].option_assignments.length === 1 ||
              optionIndex === this.updatedProdType.products[variationIndex].option_assignments.length - 1) {
            // do nothing
          } else {
            this.updatedProdType.products[variationIndex].option_assignments.splice(optionIndex, 1)
            this.$set(this.updatedProdType.products, variationIndex, this.updatedProdType.products[variationIndex])
          }
        } else {
          // either name or value of this option name/value set is not blank
          if (optionIndex === this.updatedProdType.products[variationIndex].option_assignments.length - 1) {
            this.addVariationOption(variationIndex)
          }
          this.$set(this.updatedProdType.products, variationIndex, this.updatedProdType.products[variationIndex])
        }
      },
      addVariationOption (variationIndex) {
        this.updatedProdType.products[variationIndex].option_assignments.push({
          option: { name: '' },
          option_value: { name: ''}
        })
      },
      checkVariationFnskus (variationIndex, fnskuIndex) {
        if (this.updatedProdType.products[variationIndex].fnsku_assignments[fnskuIndex].marketplace !== undefined) {
          this.updatedProdType.products[variationIndex].fnsku_assignments[fnskuIndex].marketplace = this.updatedProdType.products[variationIndex].fnsku_assignments[fnskuIndex].marketplace.trim()
          this.updatedProdType.products[variationIndex].fnsku_assignments[fnskuIndex].fnsku = this.updatedProdType.products[variationIndex].fnsku_assignments[fnskuIndex].fnsku.trim()
        } else {
          this.updatedProdType.products[variationIndex].fnsku_assignments[fnskuIndex].marketplace = ''
          this.updatedProdType.products[variationIndex].fnsku_assignments[fnskuIndex].fnsku = ''
        }

        if (this.updatedProdType.products[variationIndex].fnsku_assignments[fnskuIndex].marketplace.trim() === '' &&
            this.updatedProdType.products[variationIndex].fnsku_assignments[fnskuIndex].marketplace.trim() === '') {
          // if there is only 1 option field or if this is the last one, then leave it be
          if (this.updatedProdType.products[variationIndex].fnsku_assignments.length === 1 ||
              fnskuIndex === this.updatedProdType.products[variationIndex].fnsku_assignments.length - 1) {
            // do nothing
          } else {
            this.updatedProdType.products[variationIndex].fnsku_assignments.splice(fnskuIndex, 1)
            this.$set(this.updatedProdType.products, variationIndex, this.updatedProdType.products[variationIndex])
          }
        } else {
          // either name or value of this option name/value set is not blank
          if (fnskuIndex === this.updatedProdType.products[variationIndex].fnsku_assignments.length - 1) {
            this.addVariationFnsku(variationIndex)
            this.$set(this.updatedProdType.products, variationIndex, this.updatedProdType.products[variationIndex])
          }
        }
      },
      addVariationFnsku (variationIndex) {
        this.updatedProdType.products[variationIndex].fnsku_assignments.push({
          marketplace: '',
          fnsku: ''
        })
      },
      addVariation () {
        this.updatedProdType.products.push({
          sku: '',
          option_assignments: [
            {
              option: { name: '' },
              option_value: { name: ''}
            }
          ],
          fnsku_assignments: [
            {
              marketplace: '',
              fnsku: ''
            }
          ]
        })
      },
      changeAttributeName(e, optionIndex) {
        this.$set(this.updatedProdType.products[0].option_assignments, optionIndex, this.updatedProdType.products[0].option_assignments[optionIndex])
      },
      cancel () {
        this.$emit("cancel", true)
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }

</script>
<style>

</style>
