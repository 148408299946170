<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title" style="text-align: center;">Current Plan</h4>
    </div>
    <div class="card-content" style="text-align: center; margin-bottom: 20px;">
      <h5>{{ planName }}<br>
      <small v-if="false">${{ plan.price }} {{ plan.interval }}</small>
      </h5>
      <button class="btn btn-outline-default btn-round btn-sm" @click.prevent="goChangePlan()">Change Plan</button>
    </div>
    <!-- <hr> -->
    <div class="text-center">
      <h6>Plan Limits</h6>
      <div class="row">
        <div class="col-xs-3 ml-auto mr-auto">
          <h6>{{ user.subscription_plan.qty_codes_per_month }}
            <br>
            <small>Codes/Month</small>
          </h6>
        </div>
        <div class="col-xs-3 ml-auto mr-auto">
          <h6>{{ user.subscription_plan.qty_custom_retailers }}
            <br>
            <small>Retailers</small>
          </h6>
        </div>
        <div class="col-xs-3 ml-auto mr-auto">
          <h6>{{ user.subscription_plan.qty_users }}
            <br>
            <small>Users</small>
          </h6>
        </div>
        <div class="col-xs-3 ml-auto mr-auto">
          <h6>{{ user.subscription_plan.qty_brands }}
            <br>
            <small>Brands</small>
          </h6>
        </div>
      </div>
    </div>
    <!-- <hr> -->
    <div  class="text-center">
      <h6>{{ usage.label }}</h6>
      <div class="row">
        <div class="col-xs-3 ml-auto mr-auto">
          <h6>{{ usage.planUsageQtyCodesThisMonth }}
            <br>
            <small>Codes</small>
          </h6>
        </div>
        <div class="col-xs-3 ml-auto mr-auto">
          <h6>{{ usage.planUsageQtyCustomRetailers }}
            <br>
            <small>Retailers</small>
          </h6>
        </div>
        <div class="col-xs-3 ml-auto mr-auto">
          <h6>{{ usage.planUsageQtyUsers }}
            <br>
            <small>Users</small>
          </h6>
        </div>
        <div class="col-xs-3 ml-auto mr-auto">
          <h6>{{ usage.planUsageQtyBrands }}
            <br>
            <small>Brands</small>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from 'moment'
  import { mapGetters, mapActions } from 'vuex'
  import AccountService from 'src/services/account.service'

  export default {
    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user'
      }),
      thisMonth () {
        return moment().format('MMMM')
      },
      thisYear () {
        return moment().format('YYYY')
      },
      planName () {
        return this.user.subscription.name
      }
    },
    data () {
      return {
        plan: {
          name: 'Founders',
          price: 199.99,
          interval: 'month',
          constraints: {
            maxCodesPerMonth: 50000,
            maxCustomRetailers: 4,
            maxUsers: 4,
            maxBrands: 5
          }
        },
        usage: {
          label: 'Plan Usage',
          planUsageQtyCodesThisMonth: '...',
          planUsageQtyCustomRetailers: '...',
          planUsageQtyUsers: '...',
          planUsageQtyBrands: '...',
          periodStart: '...',
          periodEnd: '...'
        }
      }
    },
    methods: {
      async init () {
        let res = await AccountService.getPlanUsage(this.user.account_id)
        this.usage.planUsageQtyCodesThisMonth = res.data.qty_codes
        this.usage.planUsageQtyCustomRetailers = res.data.qty_retailers
        this.usage.planUsageQtyUsers = res.data.qty_users
        this.usage.planUsageQtyBrands = res.data.qty_brands
        this.usage.periodStart = this.friendlyDate(res.data.period_start)
        this.usage.periodEnd = this.friendlyDate(res.data.period_end)
        this.usage.label = 'Plan Usage for ' + this.usage.periodStart + ' to ' + this.usage.periodEnd
      },
      goChangePlan () {
        this.$router.replace({ name: 'Change Plan' })
      },
      friendlyDate (timestamp) {
        if (timestamp) return moment(timestamp).format('MMMM Do')
        else return 'never'
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }

</script>
<style>

</style>
