<template>
  <div>
    <div class="row" v-if="products.length > 0">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header">
            <h5 class="card-title" style="text-transform: uppercase;">{{ productType.name }}</h5>
          </div>
          <div class="table-responsive">
            <el-table class="table-shopping table-prod-variations"
                      style="width: 100%"
                      :data="products">
              <el-table-column min-width="120">
                <template slot-scope="props">
                  <div v-if="productType.img" class="img-container">
                    <img :src="productType.img" :alt="productType.name">
                  </div>
                  <div v-if="!productType.img" class="img-container">
                    <img src="static/img/authnet_logo_black.png" alt="Agenda">
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="220" class-name="td-name" label="Variation Details">
                <template slot-scope="props">
                  <div v-for="(option_assignment, index) in props.row.option_assignments" :key="option_assignment.option.name + '-' + option_assignment.option_value.name">
                    <small>{{ option_assignment.option.name }}: {{ option_assignment.option_value.name }}</small>
                  </div>
                  <div v-if="!detailsExist(props.row)">
                    <small>No Attributes exist</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="220" class-name="td-name" label="Amazon Details">
                <template slot-scope="props">
                  <div v-for="(fnsku_assignment, index) in props.row.fnsku_assignments" :key="fnsku_assignment.id">
                    <small>{{ fnsku_assignment.marketplace }}: {{ fnsku_assignment.fnsku }}</small>
                  </div>
                  <div v-if="!detailsExist(props.row)">
                    <small>No FNSKU's exist</small>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                class-name="td-number"
                min-width="150"
                label="Product"
                class="td-number td-quantity">
                <template slot-scope="props">
                  <p-button type="primary" round size="sm" @click.prevent="editProduct(productType, props.row)">Edit</p-button>
                  <p-button type="primary" round size="sm" v-if="!props.row.deleting && !props.row.isArchived" @click.prevent="deleteProduct(props.row)">Delete</p-button>
                  <p-button type="primary" round size="sm" v-if="props.row.deleting" disabled="disabled">
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                    Deleting...
                  </p-button>
                  <p-button type="primary" round size="sm" v-if="props.row.isArchived && !props.row.unarchiving" @click.prevent="unArchiveProduct(props.row)">Un-Archive</p-button>
                  <p-button type="primary" round size="sm" v-if="props.row.unarchiving" disabled="disabled">
                    <i class="fa fa-circle-o-notch fa-spin"></i>
                    Un-Archiving...
                  </p-button>
                </template>
              </el-table-column>
              <el-table-column
                class-name="td-number"
                min-width="150"
                label="Labels"
                class="td-number td-quantity">
                <template slot-scope="props">
                  <router-link :to="{ name: 'Label Creator', params: { productId: props.row.id }}">
                    <p-button type="primary" round size="sm">Make Auth Codes</p-button>
                  </router-link>
                  <router-link :to="{ name: 'Batch Scan In', params: { productId: props.row.id }}">
                    <p-button type="primary" round size="sm">Batch Scan-In</p-button>
                  </router-link>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import {Table, TableColumn} from 'element-ui'
  import ProductService from 'src/services/product.service'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  Vue.use(Table)
  Vue.use(TableColumn)
  export default{
    props: ['user', 'products', 'productType'],
    mixins: [NotifyMixin],
    data () {
      return {
        initialized: false,
      }
    },
    methods: {
      async init () {
        // console.log('products', this.products)
      },
      attributeString(product) {
        var str = ''
        for (var i = 0; i < product.option_assignments.length; i++) {
          if (str != '') str += '<br>'
          str += product.option_assignments[i].option.name + ': ' + product.option_assignments[i].option_value.name
        }
        return str
      },
      fnskuString(product) {
        var str = ''
        for (var i = 0; i < product.fnsku_assignments.length; i++) {
          if (str != '') str += ', '
          str += product.fnsku_assignments[i].marketplace + ': ' + product.fnsku_assignments[i].fnsku
        }
        return str
      },
      detailsExist (product) {
        return product.option_assignments.length > 0 || product.fnsku_assignments.length > 0
      },
      editProduct(productType, product) {
        this.$emit("editProduct", productType, product)
      },
      async deleteProduct (product) {
        product.deleting = true
        try {
          await ProductService.destroy(this.user.options.active_brand_id, product.id)
          this.showNotify('Product Variation deleted.', 'success', 'Success')
          this.$emit("productTypeDeleted")
        } catch (e) {
          this.showNotify(e.response.data.message, 'danger', 'Failed')
          product.deleting = false
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
.table-prod-variations .btn {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
  width: 200px !important;
  font-size: 0.875rem !important;
}
.prod-img-container {
  border-radius: 6px;
  display: block;
  height: 100px;
  overflow: hidden;
  width: 100px;
  margin: 0 auto;
  text-align: center;
}
.prod-img-container img {
  max-width: 100%;
  max-height: 100%;
}
.prod-detail-data {
  color: #9A9A9A;
}
</style>
