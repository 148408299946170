import Api from 'src/apis/api'

class StatsService {
  getBrandStatCounters (brandId) {
    return Api.get('/api/brands/' + brandId + '/stat_counters')
  }

  getRecentMonthlyValidations (brandId) {
    return Api.get('/api/brands/' + brandId + '/recent_monthly_validation_stats')
  }

  getRecentMonthlyRegistrations (brandId) {
    return Api.get('/api/brands/' + brandId + '/recent_monthly_registration_stats')
  }

  getValidationsByCountry (brandId) {
    return Api.get('/api/brands/' + brandId + '/validations_by_country')
  }

  getRegistrationsByCountry (brandId) {
    return Api.get('/api/brands/' + brandId + '/registrations_by_country')
  }

  getValidationsAndRegistrationsByCountry (brandId) {
    return Api.get('/api/brands/' + brandId + '/validations_and_registrations_by_country')
  }
}

export default new StatsService()
