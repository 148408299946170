<template>
  <div>
    <h2 style="text-align: center;">{{ activeBrand.name }} Overview</h2>
    <!--Stats cards-->
    <div class="row">
      <div class="col-lg-4 col-md-4 col-sm-6" v-for="stats in statsCards" :key="stats.key">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.value">
          <div class="stats" slot="footer">
            {{stats.footerText}}
          </div>
        </stats-card>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-sm-12">
        <recent-monthly-validations-chart :brand="activeBrand"></recent-monthly-validations-chart>
      </div>
      <div class="col-lg-6 col-sm-12">
        <recent-monthly-registrations-chart :brand="activeBrand"></recent-monthly-registrations-chart>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <validations-and-registrations-by-country :brand="activeBrand"></validations-and-registrations-by-country>
      </div>
    </div>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { mapGetters } from 'vuex'
  import ValidationsAndRegistrationsByCountry from './Components/ValidationsAndRegistrationsByCountry.vue'
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import StatsService from 'src/services/stats.service'
  import { uuid } from 'vue-uuid'
  import moment from 'moment'

  const RecentMonthlyValidationsChart = () => ({
    component: import('./Components/RecentMonthlyValidationsChart.vue')
  })

  const RecentMonthlyRegistrationsChart = () => ({
    component: import('./Components/RecentMonthlyRegistrationsChart.vue')
  })

  export default {
    components: {
      RecentMonthlyValidationsChart,
      RecentMonthlyRegistrationsChart,
      ValidationsAndRegistrationsByCountry,
      StatsCard
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      activeBrand () {
        return this.user.account.brands[_.findIndex(this.user.account.brands, b => { return b.id === this.user.options.active_brand_id })]
      }
    },
    data () {
      return {
        statsCards: [
          {
            type: 'success',
            icon: 'nc-icon nc-touch-id',
            title: 'Auth Codes',
            value: '...',
            footerText: 'Latest: ...',
            footerIcon: 'nc-icon nc-refresh-69',
            key: uuid.v4()
          },
          {
            type: 'warning',
            icon: 'nc-icon nc-key-25',
            title: 'Validations',
            value: '...',
            footerText: 'Latest: ...',
            footerIcon: 'nc-icon nc-calendar-60',
            key: uuid.v4()
          },
          {
            type: 'danger',
            icon: 'nc-icon nc-paper',
            title: 'Registrations',
            value: '...',
            footerText: 'Latest: ...',
            footerIcon: 'nc-icon nc-bell-55',
            key: uuid.v4()
          }
        ]
      }
    },
    methods: {
      init () {
        this.initStatsCards()
      },
      async initStatsCards () {
        let response = await StatsService.getBrandStatCounters(this.user.options.active_brand_id)
        // console.log('stats response data', response.data)
        this.statsCards[0].value = response.data.qty_auth_codes.toString()
        this.statsCards[0].footerText = 'Latest: ' + this.friendlyDate(response.data.auth_code_created_at_latest)
        this.statsCards[0].key = uuid.v4()
        this.statsCards[1].value = response.data.qty_validations.toString()
        this.statsCards[1].footerText = 'Latest: ' + this.friendlyDate(response.data.validation_created_at_latest)
        this.statsCards[1].key = uuid.v4()
        this.statsCards[2].value = response.data.qty_registrations.toString()
        this.statsCards[2].footerText = 'Latest: ' + this.friendlyDate(response.data.validation_created_at_latest)
        this.statsCards[2].key = uuid.v4()
      },
      friendlyDate (timestamp) {
        if (timestamp) return moment(timestamp).format('LL')
        else return 'never'
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    },
    watch: {
      user: function (newUser, oldUser) {
        // if active brand is changed, then user will update
        // so we know to get stats again (for active brand)
        this.init()
      }
    }
  }

</script>
<style>

</style>
