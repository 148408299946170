import Api from 'src/apis/api'

class PaymentMethodService {
  store (paymentMethodId) {
    let data = {
      payment_method_id: paymentMethodId
    }

    return Api({
      method: 'post',
      url: '/api/account/payment-method',
      data: data
    })
  }
}

export default new PaymentMethodService()
