import Api from 'src/apis/api'

class AuthCodeService {
  update (brandId, code, productId = null, salesChannelId = null, batchNum = null) {
    var data = {
      code: code,
      product_id: productId,
      sales_channel_id: salesChannelId,
      batch_num: batchNum
    }
    return Api.post('/api/brands/' + brandId + '/auth_codes?_method=PUT', data)
  }
}

export default new AuthCodeService()
