var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('card',{staticClass:"form-horizontal"},[_c('h4',{staticClass:"card-title",attrs:{"slot":"header"},slot:"header"},[_vm._v(" Type Validation ")]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Required Text")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"required","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Text field is required': null,"hasSuccess":passed,"name":"requiredText"},model:{value:(_vm.required),callback:function ($$v) {_vm.required=$$v},expression:"required"}})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("required:true")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Email")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Email field is required': null,"hasSuccess":passed,"name":"email"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("email:true")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Number")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"number","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Number field is required': null,"hasSuccess":passed,"name":"number"},model:{value:(_vm.number),callback:function ($$v) {_vm.number=$$v},expression:"number"}})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("number:true")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Url")]),_c('div',{staticClass:"col-sm-7"},[_c('ValidationProvider',{attrs:{"name":"url","rules":{
              required: true,
              regex: /(https?:\/\/(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])(:?\d*)\/?([a-z_\/0-9\-#.]*)\??([a-z_\/0-9\-#=&]*)/g
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var passed = ref.passed;
            var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Url field is required': null,"hasSuccess":passed,"name":"url"},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("url:true")])])]),_c('div',{staticClass:"row"},[_c('label',{staticClass:"col-sm-2 col-form-label"},[_vm._v("Equal to")]),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"equal","rules":"confirmed:confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var passed = ref.passed;
            var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The idDestination confirmation does not match': null,"hasSuccess":passed,"name":"idSource","placeholder":"#idSource"},model:{value:(_vm.idSource),callback:function ($$v) {_vm.idSource=$$v},expression:"idSource"}})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-3"},[_c('ValidationProvider',{attrs:{"name":"equalTo","vid":"confirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var passed = ref.passed;
            var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","name":"idDestination","placeholder":"#idDestination"},model:{value:(_vm.idDestination),callback:function ($$v) {_vm.idDestination=$$v},expression:"idDestination"}})]}}],null,true)})],1),_c('div',{staticClass:"col-sm-3 label-on-right"},[_c('code',[_vm._v("confirmed: 'idSource'")])])]),_c('div',{staticClass:"text-center",attrs:{"slot":"footer"},slot:"footer"},[_c('p-button',{attrs:{"type":"info","native-type":"submit"}},[_vm._v("Validate inputs")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }