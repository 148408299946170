<template>
  <ValidationObserver ref="form">
    <form @submit.prevent="validate">
      <div class="row justify-content-center">
        <div class="col-sm-12">
          <h3 class="info-text">Payment Information</h3>
          <h5 class="info-text">{{ planDetails.name }}<br>${{ planDetails.price }} per Month</h5>
        </div>
        <div class="col-lg-7">
          <fg-input type="text"
                label=""
                placeholder="Name on Card"
                v-model="name">
          </fg-input>
          <div id="card-element" style="margin: 20px auto; border: 1px solid #DDD; padding: 10px;"></div>
        </div>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
  import {Select, Option} from 'element-ui'
  import { extend } from "vee-validate"
  import { required, email } from "vee-validate/dist/rules"
  import RegistrationService from 'src/services/registration.service'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import StripeSetupIntentService from 'src/services/stripeSetupIntent.service'
  import PaymentMethodService from 'src/services/paymentMethod.service'
  import { mapGetters, mapActions } from 'vuex'

  extend("required", required);
  extend("email", email);

  export default {
    components: {
      [Select.name]: Select,
      [Option.name]: Option
    },
    props: {
      accountId: {
        type: String,
        required: true
      },
      planId: {
        type: String,
        required: true
      },
      brandName: {
        type: String,
        required: true
      },
      brandTempImgId: {
        type: String,
        required: true
      }
    },
    mixins: [NotifyMixin],
    data() {
      return {
        loading: true,
        cardInputVisible: false,
        existingCardVisible: false,
        stripeSetupIntent: '',
        stripeAPIToken: process.env.VUE_APP_STRIPE_PUB_KEY,
        stripe: '',
        elements: '',
        card: '',
        name: '',
        style: {
          base: {
            iconColor: '#66615b',
            color: '#66615b',
            fontWeight: 400,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '15px',
            '::placeholder': {
              color: '#66615b',
            },
            ':-webkit-autofill': {
              color: '#66615b',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        }
      };
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      planDetails () {
        return this.getPlanDetails(this.planId)
      }
    },
    methods: {
      ...mapActions({
        signOutAction: 'auth/signOut'
      }),
      async init () {
        this.$emit('loading', true);
        console.log('initializing stripe')
        this.getStripeSetupIntent()
        this.includeStripe('js.stripe.com/v3/', function(){
            this.configureStripe()
            this.loading = false
        }.bind(this) )
      },
      async getStripeSetupIntent () {
        let response = await RegistrationService.createStripeSetupIntent(this.accountId)
        this.stripeSetupIntent = response.data
        // console.log('stripeSetupIntent', this.stripeSetupIntent)
      },
      includeStripe( URL, callback ){
        let documentTag = document, tag = 'script',
            object = documentTag.createElement(tag),
            scriptTag = documentTag.getElementsByTagName(tag)[0]
        object.src = '//' + URL
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false) }
        scriptTag.parentNode.insertBefore(object, scriptTag)
      },
      configureStripe (){
        this.stripe = Stripe( this.stripeAPIToken );

        this.elements = this.stripe.elements();
        this.card = this.elements.create('card', { style: this.style, hidePostalCode: true });

        this.card.mount('#card-element');
        this.loading = false
        this.$emit('loading', false)
      },
      validate() {
        return this.$refs.form.validate().then(res => {
          if (!res) {
            return;
          }
          this.submit()
          return
          // this.$emit("on-validated", res);
          // return res;
        });
      },
      getPlanDetails (planId) {
        if (planId === process.env.VUE_APP_STRIPE_PLAN_1) {
          return { name: 'Business Startup 500', price: 49.99 }
        } else if (planId === process.env.VUE_APP_STRIPE_PLAN_2) {
          return { name: 'Small Business 2000', price: 89.99 }
        } else {
          return { name: 'Growing Professional 8000', price: 199 }
        }
      },
      submit() {
        this.loading = true
        this.addPaymentStatus = 1
        this.$emit('loading', true);

        this.stripe.confirmCardSetup(
            this.stripeSetupIntent.client_secret, {
                payment_method: {
                    card: this.card,
                    billing_details: {
                        name: this.name
                    }
                }
            }
        ).then(function(result) {
            if (result.error) {
                this.addPaymentStatus = 3;
                this.addPaymentStatusError = result.error.message
                this.loading = false
                this.$emit('loading', false);
            } else {
                this.savePaymentMethod( result.setupIntent.payment_method );
                this.addPaymentStatus = 2;
                this.card.clear()
                this.name = '';
            }
        }.bind(this));
      },
      async savePaymentMethod (payment_method_id) {
        try {
        let result = await RegistrationService.createSubscription(this.accountId, this.planId, payment_method_id, this.brandName, this.brandTempImgId)
        this.showNotify('Account created! Please log in.', 'success', 'Success')
        this.loading = true
        console.log(result)
        if (this.user) await this.signOutAction()
        this.$router.replace({ name: 'Login' })
        } catch (err) {
          this.showNotify('Payment info failed to save.', 'danger', 'Failed')
          this.loading = false
          this.$emit('loading', false);
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
    // watch: {
    //   accountId: function (newAccountId, oldAccountId) {
    //     console.log('accountId', newAccountId)
    //   }
    // }
  }
</script>
<style>
</style>
