<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page ">
      <div class="full-page register-page section-image" filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row">
              <div v-if="false" class="col-lg-5 col-md-5 ml-auto">
                <info-section class="mt-5"
                              type="primary"
                              title="Invite Code Required"
                              description="If you have an invite code you are welcome to register now, otherwise please join the waiting list."
                              icon="nc-icon nc-key-25">

                </info-section>
                <info-section type="primary"
                              title="Protect Your Brand &amp; Products"
                              description="Build authenticity into your brand and make it virtually impossible for counterfeiters to provide products that customers can confirm are authentic."
                              icon="nc-icon nc-app">

                </info-section>

                <info-section type="primary"
                              title="Protect &amp; Engage Your Customers"
                              description="Open channels of communication with your valued customers &amp; give them the tools they need to verify that the products they purchase are genuine."
                              icon="fa fa-users">

                </info-section>
              </div>
              <div class="col-lg-12 col-md-12 mr-auto">
                <form @submit.prevent="register">
                  <card type="signup" class="text-center" id="signup-card">
                    <template slot="header">
                      <h4 class="card-title">Register</h4>
                      <div class="social">
                        <p class="card-description"> If you have an invite code you are welcome to register now, otherwise please <a href="http://localhost:8000/login#/login">join the waiting list</a>. </p>
                      </div>
                    </template>

                    <ValidationProvider
                      name="inviteCode"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                    <fg-input v-model="form.inviteCode"
                              type="text"
                              :error="failed ? 'The Invite code field is required': null"
                              :hasSuccess="passed"
                              name="inviteCode"
                              @blur="checkInviteCode" addon-left-icon="nc-icon nc-lock-circle-open" placeholder="Invite code...">
                    </fg-input>
                    </ValidationProvider>
                    
                    <ValidationProvider
                      name="name"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                    <fg-input v-model="form.name"
                              type="text"
                              :error="failed ? 'The Name field is required': null"
                              :hasSuccess="passed"
                              name="name"
                              addon-left-icon="nc-icon nc-single-02" placeholder="Name..."></fg-input>
                    </ValidationProvider>
                    
                    <ValidationProvider
                      name="email"
                      rules="required|email"
                      v-slot="{ passed, failed }"
                    >
                    <fg-input v-model="form.email"
                              type="email"
                              :error="failed ? 'The Email field is required': null"
                              :hasSuccess="passed"
                              name="email"
                              addon-left-icon="nc-icon nc-email-85" placeholder="Email...">
                    </fg-input>
                    </ValidationProvider>
                    
                    <ValidationProvider
                      name="password"
                      rules="required"
                      v-slot="{ passed, failed }"
                    >
                    <fg-input v-model="form.password"
                              type="password"
                              :error="failed ? 'Password must contain at least 6 chars, 1 capital, 1 lowercase, 1 number': null"
                              :hasSuccess="passed"
                              name="password"
                              addon-left-icon="nc-icon nc-key-25" placeholder="Password...">
                    </fg-input>
                    </ValidationProvider>

                    <p-checkbox class="text-left" v-model="form.acceptTerms">
                      I agree to the
                      <a href="https://www.authentic.net/terms" target="_blank">terms and conditions</a>.
                    </p-checkbox>

                    <p-button v-show="submitEnabled" @click.prevent="register" native-type="submit" slot="footer" type="info" round>Get Started</p-button>
                    <p-button v-show="!submitEnabled" disabled="disabled" slot="footer" type="info" round>Get Started</p-button>
                  </card>
                </form>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background"
             style="background-image: url(static/img/background/architecture-barge-bay-beach-161764.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import AppNavbar from './Layout/AppNavbar'
  import AppFooter from './Layout/AppFooter'
  import { Card, Checkbox, Button, InfoSection } from 'src/components/UIComponents';
  import InviteCodeService from 'src/services/inviteCode.service'
  import RegistrationService from 'src/services/registration.service'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { extend } from "vee-validate";
  import { required, email } from "vee-validate/dist/rules";

  extend("email", email);
  extend("required", required);

  export default {
    components: {
      Card,
      AppNavbar,
      AppFooter,
      InfoSection,
      [Checkbox.name]: Checkbox,
      [Button.name]: Button
    },
    mixins: [NotifyMixin],
    computed: {
      submitEnabled () {
        return this.form.name != '' && this.inviteCodeIsValid && this.form.name != '' && this.form.email != '' && this.form.acceptTerms && this.form.password != ''
      }
    },
    data(){
      return {
        form: {
          name: '',
          inviteCode: '',
          email: '',
          password: '',
          acceptTerms: true
        },
        inviteCodeIsValid: false
      }
    },
    methods: {
      init () {
        // nothing
      },
      async checkInviteCode () {
        try {
          let response = await InviteCodeService.show(this.form.inviteCode)
          console.log('response data', response.data)
          if (response.data.qty_used < response.data.qty) {
            this.inviteCodeIsValid = true
            if (response.data.name) this.form.name = response.data.name
            if (response.data.email) this.form.email = response.data.email
          } else {
            this.inviteCodeIsValid = false
            this.showNotify('Sorry, this invite code has already been used or is invalid.', 'danger')
          }
        } catch (e) {
          this.inviteCodeIsValid = false
          this.showNotify('Sorry, this invite code was not found.', 'danger')
        }
      },
      async register () {
        console.log('register')
        try {
          await RegistrationService.registerViaCode(this.form.inviteCode, this.form.name, this.form.email, this.form.password)
          this.$router.replace({ name: 'Login' })
        } catch (e) {
          console.log(e)
          this.showNotify(e.response.data.message, 'danger', 'Failed')
        }
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
#signup-card {
  max-width: 400px;
  margin: auto;
}
</style>
