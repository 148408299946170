<template>
  <div class="card">
    <div class="card-header">
      <h4 class="card-title">Brand Validations + Registrations by Country</h4>
      <p class="category">Total Validations + Registrations for {{brand.name}}</p>
    </div>
    <div class="card-content" style="padding-left: 15px; padding-right: 15px;">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-6" v-for="country in registrationsByCountryData.countries" :key="registrationsByCountryKey(country)">
          <div class="table-responsive">
            <table class="table" style="background-color: #e3e3e3;">
              <tbody>
              <tr>
                <td>
                  <div class="flag" style="width: 25px;">
                    <img :src="getFlagIcon(country.country_code)" :alt="country.country_code">
                  </div>
                </td>
                <td>{{country.country_code}}</td>
                <td class="text-right">
                  {{country.qty}}
                </td>
                <td class="text-right">
                  {{getPercentage(country.qty, registrationsByCountryData.total)}}%
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import StatsService from 'src/services/stats.service'
  import { uuid } from 'vue-uuid'
  import Loading from 'src/components/Dashboard/Layout/LoadingMainPanel.vue'

  const StatsWorldMap = () => ({
    component: import('./StatsWorldMap.vue'),
    loading: Loading,
    delay: 200
  })

  export default{
    props: {
      brand: {
        type: Object,
        required: true
      }
    },
    components: {
      StatsWorldMap
    },
    data () {
      return {
        initialized: false,
        registrationsByCountryData: {
          countries: [],
          total: 0
        }
      }
    },
    methods: {
      init () {
        this.loadRegistrationsByCountry()
      },
      async loadRegistrationsByCountry () {
        this.initialized = false
        let response = await StatsService.getValidationsAndRegistrationsByCountry(this.brand.id)
        this.registrationsByCountryData = {
          countries: response.data.countries,
          total: response.data.total
        }
        this.initialized = true
      },
      registrationsByCountryKey (country) {
        return country.country_code + uuid.v4()
      },
      getFlagIcon (countryCode) {
        // return 'static/img/flags/' + countryCode + '.png'
        return '/static/img/flag-icons/' + countryCode.toLowerCase() + '.svg'
      },
      getPercentage (qty, totalQty) {
        return Math.round((qty / totalQty) * 100 * 100) / 100
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    },
    watch: {
      brand: function (newBrand, oldBrand) {
        // if active brand is changed, then user will update
        // so we know to get stats again (for active brand)
        this.init()
      }
    }
  }
</script>
<style>
</style>
