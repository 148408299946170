import Api from 'src/apis/api'

class AccountService {
  get (accountId) {
    return Api.get('/api/account/' + accountId)
  }

  update (accountId, accountData) {
    return Api.post('/api/account/' + accountId + '?_method=PUT', accountData)
  }

  getPlanUsage (accountId) {
    return Api.get('/api/account/' + accountId + '/plan-usage')
  }
}

export default new AccountService()
