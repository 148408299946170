import Api from 'src/apis/api'

class AuthCodeRegistrationService {
  create (regData) {
    // let data = {
    //   code: authCode
    // }

    return Api({
      method: 'post',
      url: '/api/code-registration',
      data: regData
    })
  }

  getBrandRegistrations (brandId, page = 1, search = '') {
    return Api.get('/api/brands/' + brandId + '/registrations?page=' + page + '&search=' + search)
  }
}

export default new AuthCodeRegistrationService()
