var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"register-page"},[_c('app-navbar'),_c('div',{staticClass:"wrapper wrapper-full-page "},[_c('div',{staticClass:"full-page register-page section-image",attrs:{"filter-color":"black"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[(false)?_c('div',{staticClass:"col-lg-5 col-md-5 ml-auto"},[_c('info-section',{staticClass:"mt-5",attrs:{"type":"primary","title":"Invite Code Required","description":"If you have an invite code you are welcome to register now, otherwise please join the waiting list.","icon":"nc-icon nc-key-25"}}),_c('info-section',{attrs:{"type":"primary","title":"Protect Your Brand & Products","description":"Build authenticity into your brand and make it virtually impossible for counterfeiters to provide products that customers can confirm are authentic.","icon":"nc-icon nc-app"}}),_c('info-section',{attrs:{"type":"primary","title":"Protect & Engage Your Customers","description":"Open channels of communication with your valued customers & give them the tools they need to verify that the products they purchase are genuine.","icon":"fa fa-users"}})],1):_vm._e(),_c('div',{staticClass:"col-lg-12 col-md-12 mr-auto"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('card',{staticClass:"text-center",attrs:{"type":"signup","id":"signup-card"}},[_c('template',{slot:"header"},[_c('h4',{staticClass:"card-title"},[_vm._v("Register")]),_c('div',{staticClass:"social"},[_c('p',{staticClass:"card-description"},[_vm._v(" If you have an invite code you are welcome to register now, otherwise please "),_c('a',{attrs:{"href":"http://localhost:8000/login#/login"}},[_vm._v("join the waiting list")]),_vm._v(". ")])])]),_c('ValidationProvider',{attrs:{"name":"inviteCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Invite code field is required': null,"hasSuccess":passed,"name":"inviteCode","addon-left-icon":"nc-icon nc-lock-circle-open","placeholder":"Invite code..."},on:{"blur":_vm.checkInviteCode},model:{value:(_vm.form.inviteCode),callback:function ($$v) {_vm.$set(_vm.form, "inviteCode", $$v)},expression:"form.inviteCode"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Name field is required': null,"hasSuccess":passed,"name":"name","addon-left-icon":"nc-icon nc-single-02","placeholder":"Name..."},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? 'The Email field is required': null,"hasSuccess":passed,"name":"email","addon-left-icon":"nc-icon nc-email-85","placeholder":"Email..."},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'Password must contain at least 6 chars, 1 capital, 1 lowercase, 1 number': null,"hasSuccess":passed,"name":"password","addon-left-icon":"nc-icon nc-key-25","placeholder":"Password..."},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}])}),_c('p-checkbox',{staticClass:"text-left",model:{value:(_vm.form.acceptTerms),callback:function ($$v) {_vm.$set(_vm.form, "acceptTerms", $$v)},expression:"form.acceptTerms"}},[_vm._v(" I agree to the "),_c('a',{attrs:{"href":"https://www.authentic.net/terms","target":"_blank"}},[_vm._v("terms and conditions")]),_vm._v(". ")]),_c('p-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.submitEnabled),expression:"submitEnabled"}],attrs:{"slot":"footer","native-type":"submit","type":"info","round":""},on:{"click":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}},slot:"footer"},[_vm._v("Get Started")]),_c('p-button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.submitEnabled),expression:"!submitEnabled"}],attrs:{"slot":"footer","disabled":"disabled","type":"info","round":""},slot:"footer"},[_vm._v("Get Started")])],2)],1)])])])]),_c('app-footer'),_c('div',{staticClass:"full-page-background",staticStyle:{"background-image":"url(static/img/background/architecture-barge-bay-beach-161764.jpg)"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }