<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-lg-10">
        <h3 class="info-text">Select Plan</h3>
      </div>
      <div class="col-lg-12">
        <div class="row" ref="plans">
          <div class="col-md-4">
            <div class="card plan-card" v-bind:class="model.planId == plan1.stripeId ? 'featured-plan' : 'not-featured-plan'">
              <div class="card-header">
                <h5 class="card-title">Business Startup<br>500</h5>
                <p class="category">$49.99 PER MONTH</p>
              </div>
              <button v-if="model.planId != plan1.stripeId" class="btn btn-success btn-round" @click="selectPlan(plan1.stripeId)">Select</button>
              <button v-if="model.planId == plan1.stripeId" class="btn btn-default btn-round" disabled="disabled">Selected</button>
              <div class="card-content">
                <div class="plan-desc"><h6>Business startup essentials</h6></div>
                <h6 class="plan-inc">Includes:</h6>
                <ul>
                  <li><div>Protect products with 500 Authentic labels per month</div></li>
                  <li><div>Digital Dashboard</div></li>
                  <li><div>Amazon.com + 1 additional Retailer / Sales Channel</div></li>
                  <li><div>1 User</div></li>
                  <li><div>1 Brand</div></li>
                  <li><div>Dymo Printer Integration</div></li>
                  <li><div>FBA / Marketplace label printing</div></li>
                  <li><div>Unlimited Products / Variations</div></li>
                  <li><div>Ideal for Small Business Startups</div></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card plan-card" v-bind:class="model.planId == plan2.stripeId ? 'featured-plan' : 'not-featured-plan'">
              <div class="card-header">
                <h5 class="card-title">Small Business<br>2000</h5>
                <p class="category">$89.99 PER MONTH</p>
              </div>
              <button v-if="model.planId != plan2.stripeId" class="btn btn-success btn-round" @click="selectPlan(plan2.stripeId)">Select</button>
              <button v-if="model.planId == plan2.stripeId" class="btn btn-default btn-round" disabled="disabled">Selected</button>
              <div class="card-content">
                <div class="plan-desc"><h6>Flexibility for growth</h6></div>
                <h6 class="plan-inc">Includes:</h6>
                <ul>
                  <li><div>Protect products with 2000 Authentic labels per month</div></li>
                  <li><div>Digital Dashboard</div></li>
                  <li><div>All Amazon + 2 additional Retailers / Sales Channels</div></li>
                  <li><div>2 Users</div></li>
                  <li><div>2 Brands</div></li>
                  <li><div>Dymo Printer Integration</div></li>
                  <li><div>FBA / Marketplace label printing</div></li>
                  <li><div>Unlimited Products / Variations</div></li>
                  <li><div>Enhanced security &amp; encryption</div></li>
                  <li><div>Perfect for the growing Small Business</div></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card plan-card" v-bind:class="model.planId == plan3.stripeId ? 'featured-plan' : 'not-featured-plan'">
              <div class="card-header">
                <h5 class="card-title">Growing Professional<br>8000</h5>
                <p class="category">$199 PER MONTH</p>
              </div>
              <button v-if="model.planId != plan3.stripeId" class="btn btn-success btn-round" @click="selectPlan(plan3.stripeId)">Select</button>
              <button v-if="model.planId == plan3.stripeId" class="btn btn-default btn-round" disabled="disabled">Selected</button>
              <div class="card-content">
                <div class="plan-desc"><h6>Stability at scale</h6></div>
                <h6 class="plan-inc">Includes:</h6>
                <ul>
                  <li><div>Protect products with 8000 Authentic labels per month</div></li>
                  <li><div>Digital Dashboard</div></li>
                  <li><div>All Amazon + 4 additional Retailers / Sales Channels</div></li>
                  <li><div>4 Users</div></li>
                  <li><div>4 Brands</div></li>
                  <li><div>Dymo Printer Integration</div></li>
                  <li><div>FBA / Marketplace label printing</div></li>
                  <li><div>Unlimited Products / Variations</div></li>
                  <li><div>Enhanced security &amp; encryption</div></li>
                  <li><div>Digital Branded Landing Page</div></li>
                  <li><div>Digital Registration Warranty Page</div></li>
                  <li><div>Perfect for the growing Small Business</div></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row plans-footer">
          <p>Contact us for entrprise level plans and pricing for customized add-ons</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {IconCheckbox} from 'src/components/UIComponents'
  import { NotifyMixin } from 'src/mixins/notify.mixin'

  export default {
    components: {
      IconCheckbox
    },
    mixins: [NotifyMixin],
    data() {
      return {
        plan1: {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_1
        },
        plan2: {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_2
        },
        plan3: {
          stripeId: process.env.VUE_APP_STRIPE_PLAN_3
        },
        model: {
          planId: ''
        }
      }
    },
    methods: {
      validate() {
        if (this.planId == '') {
          this.showNotify('Please select a plan.', 'danger')
          return
        }
        this.$emit('on-validated', true, this.model)
        return Promise.resolve(true)
      },
      selectPlan (planId) {
        this.model.planId = planId
      }
    }
  }
</script>
<style>
.plan-card {
  text-align: center;
  justify-content: center;
}
.plan-card .btn {
  width: 80%;
  margin: 0px auto;
}
.plan-card .no-cc-required {
  color: #9A9A9A;
  display: none;
}
.plan-card .plan-desc {
  border-bottom: 1px solid #dee1e1;
}
.plan-card .plan-desc h6 {
  text-transform: none;
}
.plan-card .plan-inc {
  padding-top: 10px;
  padding-bottom: 10px;
}
.plan-card ul {
  list-style-type: none;
  padding-left: 0;
}
.plan-card ul li div {
  padding-top: 10px;
  padding-bottom: 10px;
}
.plan-card ul li:before {
  display: block;
  width: 50%;
  height: 0;
  margin: .44444rem auto;
  border-top: 1px solid #dee1e1;
  content: "";
}
.plan-card .category {
  font-size: 1em;
  font-weight: bold;
  color: #000;
}
.featured-plan {
    -webkit-box-shadow: 0 0 28px rgba(22,32,32,.25), 0 10px 10px rgba(22,32,32,.22);
    box-shadow: 0 0 28px rgba(22,32,32,.25), 0 10px 10px rgba(22,32,32,.22);
    padding-top: 3.11111rem;
    padding-bottom: 3.11111rem;
}
.not-featured-plan {
  margin-top: 3.11111rem;
  margin-bottom: 3.11111rem;
}
.plans-footer {
  margin-top: 40px;
  text-align: center;
  justify-content: center;
}
</style>
