import Api from 'src/apis/api'

class SalesChannelService {
  index (brandId, type = 'all') {
    return Api.get('/api/brands/' + brandId + '/sales_channels?type=' + type)
  }

  create (brandId, salesChannelData) {
    const data = new FormData()
    data.append('name', salesChannelData.name)
    data.append('img_temp_file_id', salesChannelData.imgTempFileId)
    return Api.post('/api/brands/' + brandId + '/sales_channels', data)
  }

  destroy (brandId, id) {
    return Api({
      method: 'delete',
      url: '/api/brands/' + brandId + '/sales_channels',
      data: { id: id }
    })
  }
}

export default new SalesChannelService()
