<template>
  <div class="register-page">
    <app-navbar></app-navbar>
    <div class="wrapper wrapper-full-page ">
      <div class="full-page register-page section-image" filter-color="black">
        <div class="content">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="col-md-10 mr-auto ml-auto">
                  <wizard v-if="initialized" :key="wizardKey" @complete="wizardComplete" :next-button-loading="nextButtonLoading" ref="wizard" class="reg-wizard">
                    <template slot="header">
                      <h3 class="card-title">Build your profile</h3>
                      <h3 class="description">This information will let us know more about you.</h3>
                    </template>

                    <wizard-tab v-if="!accountCreated" :before-change="() => validateStep('step1')">
                      <template slot="label">
                        <i class="nc-icon nc-single-02"></i>
                        About
                      </template>
                      <about-step ref="step1" @on-validated="onStepValidated" :inputsDisabled="true" :wizardModel="wizardModel"></about-step>
                    </wizard-tab>

                    <wizard-tab :before-change="() => validateStep('step2')">
                      <template slot="label">
                        <i class="fa fa-building-o"></i>
                        Brand
                      </template>
                      <brand-step ref="step2" @on-validated="onStepValidated"></brand-step>
                    </wizard-tab>

                    <wizard-tab :before-change="() => validateStep('step3')">
                      <template slot="label" >
                        <i class="fa fa-shopping-cart"></i>
                        Plan
                      </template>
                      <plan-step ref="step3" @on-validated="onStepValidated"></plan-step>
                    </wizard-tab>

                    <wizard-tab :before-change="() => validateStep('step5')">
                      <template slot="label">
                        <i class="fa fa-credit-card"></i>
                        Checkout
                      </template>
                      <checkout-step ref="step5" v-if="checkoutReady"
                        :accountId="wizardModel.accountId"
                        :planId="wizardModel.planId"
                        :brandName="wizardModel.brandName"
                        :brandTempImgId="wizardModel.imgTempFileId"
                        @loading="setNextButtonLoading"></checkout-step>
                    </wizard-tab>
                  </wizard>
              </div>
            </div>
          </div>
        </div>
        <app-footer></app-footer>
        <div class="full-page-background"
             style="background-image: url(static/img/background/architecture-barge-bay-beach-161764.jpg) "></div>
      </div>
    </div>
  </div>
</template>
<script>
  import AppNavbar from '../Layout/AppNavbar'
  import AppFooter from '../Layout/AppFooter'
  import AboutStep from './Wizard/AboutStep.vue'
  import PlanStep from './Wizard/PlanStep.vue'
  import BrandStep from './Wizard/BrandStep.vue'
  import CheckoutStep from './Wizard/CheckoutStep.vue'
  import {Wizard, WizardTab} from 'src/components/UIComponents'
  import { mapGetters } from 'vuex'
  import { uuid } from 'vue-uuid'

  export default {
    components: {
      AppNavbar,
      AppFooter,
      AboutStep,
      PlanStep,
      BrandStep,
      CheckoutStep,
      Wizard,
      WizardTab
    },
    data(){
      return {
        initialized: false,
        nextButtonLoading: false,
        wizardKey: uuid.v4(),
        wizardModel: {name: 'Jeremy Bohr', accountId: ''}
      }
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      accountCreated () {
        return this.wizardModel.accountId !== null && this.wizardModel.accountId !== ''
      },
      checkoutReady () {
        return this.wizardModel.accountId && this.wizardModel.planId
      }
    },
    methods: {
      init () {
        var actId = null
        if (this.$route.params.aid && this.$route.params.aid) {
          actId = this.$route.params.aid
        } else if (this.$route.query.aid && this.$route.query.aid) {
          actId = this.$route.query.aid
        }
        console.log('actId', actId)
        if (actId !== null) this.verifyAccountId(actId)
        else this.initialized = true
      },
      async verifyAccountId (accountId) {
        try {
          if (this.user.account_id === accountId) {
            this.wizardModel.accountId = accountId
            this.initialized = true
          } else {
            this.$router.replace({ name: 'Login' })
          }
        } catch (e) {
          this.$router.replace({ name: 'Login' })
        }
      },
      validateStep(ref) {
        return this.$refs[ref].validate()
      },
      onStepValidated(validated, model) {
        // console.log('validated, model', validated, model)
        let accountAlreadyCreated = this.accountCreated
        this.wizardModel = {...this.wizardModel, ...model}
        if (!accountAlreadyCreated && this.accountCreated) {
          this.wizardKey = uuid.v4()
        }
      },
      wizardComplete() {
        Swal.fire({
          title: "Good job!",
          text: "You clicked the finish button!",
          type: "success",
          confirmButtonClass: "btn btn-success",
          buttonsStyling: false
        });
      },
      setNextButtonLoading (isLoading) {
        this.nextButtonLoading = isLoading
      },
      toggleNavbar () {
        document.body.classList.toggle('nav-open')
      },
      closeMenu () {
        document.body.classList.remove('nav-open')
        document.body.classList.remove('off-canvas-sidebar')
      }
    },
    beforeDestroy () {
      this.closeMenu()
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
#signup-card {
  max-width: 400px;
  margin: auto;
}
.reg-wizard {
  margin-bottom: 60px;
}
</style>
