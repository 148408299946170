import Api from 'src/apis/api'

class AuthCodeAuthenticationService {
  create (authCode) {
    let data = {
      code: authCode
    }

    return Api({
      method: 'post',
      url: '/api/code-authentication',
      data: data
    })
  }
}

export default new AuthCodeAuthenticationService()
