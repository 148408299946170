<template>
  <div>
    <form @submit.prevent="submit">
      <div style="text-align: center; margin-top: 40px;">
        <h2>Thank you for authenticating your<br>{{ this.codeData.productType.name }}!</h2>
        <h4>Please submit your registration.</h4>
        <img :src="this.codeData.brand.logo_img" :alt="this.codeData.brand.name" style="max-width: 200px; max-height: 100px;"><br>
        <img :src="this.codeData.productType.img" :alt="this.codeData.productType.name" style="max-width: 200px; max-height: 200px;">
        <div v-for="(option_assignment, index) in codeData.product.option_assignments" :key="'option_assignment-' + index">
          <strong>{{ option_assignment.option.name }}:</strong> {{ option_assignment.option_value.name }}<br>
        </div>
      </div>
      <div class="col-md-4 ml-auto mr-auto">
        <fg-input type="text"
                  label="First Name"
                  v-model="model.first_name">
        </fg-input>
        <fg-input type="text"
                  label="Last Name"
                  v-model="model.last_name">
        </fg-input>
        <fg-input type="text"
                  label="Email"
                  v-model="model.email">
        </fg-input>
        <fg-input type="text"
                  label="Address Line 1"
                  v-model="model.address1">
        </fg-input>
        <fg-input type="text"
                  label="Address Line 2"
                  v-model="model.address2">
        </fg-input>
        <fg-input type="text"
                  label="City"
                  v-model="model.city">
        </fg-input>
        <fg-input type="text"
                  label="State"
                  v-model="model.state">
        </fg-input>
        <fg-input type="text"
                  label="Postal Code"
                  v-model="model.zip">
        </fg-input>
        <fg-input type="text"
                  label="Country"
                  v-model="model.country">
        </fg-input>
        <p-button native-type="submit" slot="footer" type="primary" v-if="!loading" round block class="mb-3">Submit</p-button>
        <p-button native-type="submit" slot="footer" type="primary" v-if="loading" round block class="mb-3" disabled="disabled">
          <span class="btn-label">
            <i class="fa fa-circle-o-notch fa-spin"></i>
          </span>
          Submit
        </p-button>
      </div>
    </form>
  </div>
</template>
<script>
// import { stripeKey, stripeOptions } from './stripeConfig.json'
// import { Card, createToken } from 'vue-stripe-elements-plus'
// import { uuid } from 'vue-uuid'
import swal from 'sweetalert2'
// import qs from 'qs'
import AuthCodeRegistrationService from 'src/services/authCodeRegistration.service'

export default {
  props: {
    code: {
      type: String,
      required: true
    },
    codeData: {
      type: Object,
      required: true
    },
    redirectUrl: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      myvariable: 4,
      modelValidations: {
        first_name: {
          required: true
        },
        last_name: {
          required: true
        },
        email: {
          required: true,
          email: true
        },
        city: {
          required: true
        },
        state: {
          required: true
        },
        zip: {
          required: true
        },
        country: {
          required: true
        }
      },
      stripe_plan_fetched: false,
      loading: false,
      step: 1,
      complete: false,
      stripe_customer_id: '',
      model: {
        first_name: '',
        last_name: '',
        email: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: ''
      },
      submitData: {}
    }
  },
  methods: {
    async init () {
      this.submitData.code = this.code
      // console.log('code, data', this.code, this.codeData)
      // this.submitData.clientIpData = await this.getClientIpData()
      // this.submitData.clientIp = await this.getClientIp()
    },
    toggleNavbar () {
      document.body.classList.toggle('nav-open')
    },
    closeMenu () {
      document.body.classList.remove('nav-open')
      document.body.classList.remove('off-canvas-sidebar')
    },
    async submit () {
      this.submitData = this.model
      this.submitData.code = this.code
      console.log(this.submitData)
      try {
        await AuthCodeRegistrationService.create(this.submitData)
        var text = 'You have successfully registered your product.'
        if (this.redirectUrl) {
          swal.fire({
            title: `Thank you!`,
            text: text,
            buttonsStyling: false,
            confirmButtonClass: 'btn btn-info',
            type: 'success'
          }).then(() => {
            window.location.replace(this.redirectUrl)
          }).catch(error => {
            window.location.replace(this.redirectUrl)
          })
        } else {
          this.$router.replace('/thank-you')
        }
      } catch (e) {
        console.log('error')
      }
    },
    // signup () {
    //   console.log('signing up')
    //   let signup = firebase.functions().httpsCallable('signup')
    //   signup(this.model)
    //   .then(result => {
    //     console.log(result)
    //   })
    //   .catch(err => {
    //     console.error(err.message)
    //   })
    // },
    beforeDestroy () {
      this.closeMenu()
    },
    getError (fieldName) {
      return this.errors.first(fieldName)
    },
    validateBeforeSubmit () {
      this.loading = true
      this.$validator.validateAll().then(isValid => {
        if (isValid) {
          this.submit()
          // this.signup()
        } else {
          this.loading = false
        }
      })
    }
  },

  mounted: function () {
    this.$nextTick(function () {
      this.init()
    })
  }
}
</script>
