<template>
  <div>
    <div class="row">
      <div class="col-md-4 ml-auto">
        <form @submit.prevent="search">
          <fg-input v-if="!loading" addon-right-icon="nc-icon nc-zoom-split" v-model="searchQuery"
                    placeholder="Search..."></fg-input>
          <fg-input v-if="loading" addon-right-icon="fa fa-circle-o-notch fa-spin" v-model="searchQuery" disabled="disabled"
                    placeholder="Search..."></fg-input>
        </form>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-6" v-for="reg in registrations" :key="reg.id">
        <registration-card :registration="reg"></registration-card>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 pagination-info">
        <p class="category">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.total}} entries</p>
      </div>
      <div class="col-sm-6">
        <p-pagination class="pull-right"
                      v-model="pagination.currentPage"
                      :per-page="pagination.perPage"
                      :total="pagination.total"
                      @input="changePage">
        </p-pagination>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import AuthCodeRegistrationService from 'src/services/authCodeRegistration.service'
  import RegistrationsCsvService from 'src/services/registrationsCsv.service'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import RegistrationCard from './Components/RegistrationCard.vue'
  import PPagination from 'src/components/UIComponents/Pagination.vue'

  export default{
    components: {
      RegistrationCard,
      PPagination
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      activeBrand () {
        return this.user.account.brands[_.findIndex(this.user.account.brands, b => { return b.id === this.user.options.active_brand_id })]
      }
    },
    mixins: [NotifyMixin],
    data () {
      return {
        loading: false,
        registrations: [],
        searchQuery: '',
        pagination: {
          currentPage: 1,
          perPage: 18,
          total: 1,
          from: 0,
          to: 0
        }
      }
    },
    methods: {
      async init () {
        this.search()
      },
      async getRegistrations () {
        let response = await AuthCodeRegistrationService.getBrandRegistrations(this.activeBrand.id)
        this.registrations = response.data.data
        this.initialized = true
      },
      async search () {
        this.loading = true;
        let response = await AuthCodeRegistrationService.getBrandRegistrations(this.activeBrand.id, this.pagination.currentPage, this.searchQuery)
        this.loading = false;
        let data = response.data;
        this.pagination.from = data.from
        this.pagination.to = data.to
        this.pagination.total = data.total
        this.pagination.currentPage = data.current_page
        this.pagination.perPage = data.per_page
        this.registrations = data.data
      },
      changePage (page) {
        this.pagination.currentPage = page
        this.search();
      },
      async downloadCsv () {
        let response = await RegistrationsCsvService.get(this.user.options.active_brand_id)
        console.log('response', response)
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    },
    watch: {
      activeBrand: function (newUser, oldUser) {
        // if active brand is changed, then user will update
        // so we know to get stats again (for active brand)
        this.init()
      }
    }
  }
</script>
<style>
</style>
