<template>
  <div class="card card-user">
    <div class="image">
      <img v-if="false" src="static/img/background/damir-bosnjak.jpg" alt="...">
    </div>
    <div class="card-body">
      <div class="author">
        <img v-if="false" class="avatar border-gray" src="static/img/mike.jpg" alt="...">
        <v-gravatar class="avatar border-gray" :email="user.email" :alt="user.name" :size="124" default-img="mm" />
        <h5 class="title">User Details</h5>
        <p class="description">
          <a href="#" @click.prevent="updateName()">{{ user.name }} <i class="fa fa-edit"></i></a><br>
          <a href="#" @click.prevent="updateEmail()">{{ user.email }} <i class="fa fa-edit"></i></a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import swal from 'sweetalert2'
  import Gravatar from 'vue-gravatar'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  // import UserService from 'src/services/user.service'
  import AccountService from 'src/services/account.service'
  import { mapGetters, mapActions } from 'vuex'

  Vue.component('v-gravatar', Gravatar)

  export default {
    computed: {
      ...mapGetters({
        authenticated: 'auth/authenticated',
        user: 'auth/user'
      })
    },
    mixins: [NotifyMixin],
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      async updateName () {
        let nameResult = await swal.fire({
          title: 'Name',
          text: `Your name as you would like it to exist on file:`,
          type: 'question',
          input: 'text',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false,
          inputValue: this.user.name
        })
        if (nameResult.value) {
          console.log('nameResult.value', nameResult.value)
          // await UserService.updateName(nameResult.value)
          await AccountService.update(this.user.account.id, {'name': nameResult.value})
          this.me()
          this.showNotify('Account details saved.', 'success', 'Success')
        }
      },
      async updateEmail () {
        let emailResult = await swal.fire({
          title: 'Email',
          text: `Email address you would like associated with your account:`,
          type: 'question',
          input: 'text',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          buttonsStyling: false,
          inputValue: this.user.email
        })
        if (emailResult.value) {
          // await UserService.updateEmail(emailResult.value)
          await AccountService.update(this.user.account.id, {'email': emailResult.value})
          this.me()
          this.showNotify('Account details saved.', 'success', 'Success')
        }
      }
    }
  }

</script>
<style>

</style>
