<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <card>
          <form class="form-horizontal" v-on:submit.prevent>
            <h4 slot="header" class="card-title">
              Batch Athentic.net Codes Scan-In
            </h4>
            <p>If you created 'Blank Auth Codes', then
              you can assign a batch of them to a product here. Just click 'BATCH SCAN-IN' on the <router-link :to="{ name: 'Products' }"><p-button type="info" round size="sm">Product</p-button></router-link> you wish to assign them to
              then provide an optional Retailer and Batch Number and click Start Scanning.
            </p>
            <div v-if="selectedProductId" class="row">
              <div class="col-sm-3">
                <img v-if="product.product_type.img" :src="product.product_type.img" :alt="product.product_type.name">
              </div>
              <div class="col-sm-9">
                <div>
                  <strong>SKU:</strong> {{product.sku}}
                </div>
                <div v-for="(option_assignment, opt_index) in product.option_assignments" :key="product.id + '-' + option_assignment.option.name">
                  <strong>{{ option_assignment.option.name }}:</strong> {{ option_assignment.option_value.name }}
                </div>
                <div v-for="(fnsku, fnsku_index) in product.fnsku_assignments" :key="product.id + '-fnsku-' + fnsku_index">
                  <strong>{{ fnsku.marketplace }}:</strong> {{ fnsku.fnsku }}
                </div>
              </div>
            </div>
            <div v-if="selectedProductId" class="row">
              <label class="col-md-3 col-form-label">Retailer</label>
              <div class="col-md-9">
                <el-select class="select-primary" style="margin-bottom: 10px;"
                          size="large"
                          placeholder="Retailer *optional"
                          v-model="retailerSelect.retailer_id">
                  <el-option v-for="option in retailerSelect.retailers"
                            class="select-danger"
                            :value="option.id"
                            :label="option.name"
                            :key="option.name">
                  </el-option>
                </el-select>
              </div>
              
              <label class="col-md-3 col-form-label">Batch / Lot Num</label>
              <div class="col-md-9">
                <fg-input v-model="model.batchNum" placeholder="*optional"></fg-input>
              </div>
            </div>

            <div v-if="selectedProductId" slot="footer" class="row">
              <div class="col-md-12">

                <router-link :to="{ name: 'Batch Scan', params: { productId: selectedProductId, retailerId: retailerSelect.retailer_id, batchNum: model.batchNum }}">
                  <p-button type="primary" round>Start Scanning</p-button>
                </router-link>

              </div>
            </div>
          </form>
        </card> <!-- end card -->

      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex'
  import SalesChannelService from 'src/services/salesChannel.service'
  import ProductService from 'src/services/product.service'

  export default{
    computed: {
      ...mapGetters({
        user: 'auth/user'
      })
    },
    data () {
      return {
        model: {
          batchNum: '',
          qty: ''
        },
        retailerSelect: {
          retailer_id: '',
          retailers: []
        },
        productSelect: {
          product_id: '',
          products: []
        },
        selectedProductId: '',
        product: {
          product_type: {},
          option_assignments: [],
          fnsku_assignments: []
        },
      }
    },
    methods: {
      async init () {
        this.selectedProductId = this.$route.params.productId
        if (this.selectedProductId) await this.loadProduct()
        this.getSalesChannels()
      },
      async loadProduct () {
        let response = await ProductService.get(this.user.options.active_brand_id, this.selectedProductId)
        console.log('response', response)
        this.product = response.data
        // this.product.data.image = await this.prodImgUrl(prodDoc.id, prodDoc.data(), '140x140')
      },
      async getSalesChannels () {
        let response = await SalesChannelService.index(this.user.options.active_brand_id)
        this.retailerSelect.retailers = response.data
      },
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
</style>
