<template>
  <div class="card">
    <div class="card-header">
      <h4 class="title" style="text-align: center;">Payment Method</h4>
    </div>
    <div v-show="existingCardVisible" class="card-content" style="text-align: center; padding-bottom: 20px;">
      <h5>{{ user.account.card_brand }}: {{ user.account.card_last_four }}</h5>
      <button class="btn btn-outline-default btn-round btn-sm" @click.prevent="showCardInput()">Update Card</button>
    </div>
    <div v-show="cardInputVisible" class="card-content" style="text-align: center; padding: 20px;">
      <fg-input type="text"
                label=""
                placeholder="Name on Card"
                v-model="name">
      </fg-input>
      <div id="card-element" style="margin: 20px auto; border: 1px solid #DDD; padding: 10px;"></div>
      <button type="button" v-if="!loading" class="btn btn-round btn-info" @click.prevent="submit()">Submit</button>
      <button type="button" v-if="loading" class="btn btn-round btn-info" disabled="disabled">
        <span class="btn-label">
          <i class="fa fa-circle-o-notch fa-spin"></i>
        </span>
        Submit
      </button>
      <div>
        *If this is the first time you are submitting payment information then you will automatically be
        enrolled in the Business Startup 500 plan at $49.99/month. You will be able to upgrade your plan
        immediately after if desired.
      </div>
    </div>
  </div>
</template>
<script>
  import StripeSetupIntentService from 'src/services/stripeSetupIntent.service'
  import PaymentMethodService from 'src/services/paymentMethod.service'
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    mixins: [NotifyMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      hasPaymentMethod () {
        return this.user.account.card_last_four != null
      }
    },
    data () {
      return {
        loading: true,
        cardInputVisible: false,
        existingCardVisible: false,
        stripeSetupIntent: '',
        stripeAPIToken: process.env.VUE_APP_STRIPE_PUB_KEY,
        stripe: '',
        elements: '',
        card: '',
        name: '',
        style: {
          base: {
            iconColor: '#66615b',
            color: '#66615b',
            fontWeight: 400,
            fontFamily:
              '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '15px',
            '::placeholder': {
              color: '#66615b',
            },
            ':-webkit-autofill': {
              color: '#66615b',
            },
          },
          invalid: {
            color: '#fa755a',
            iconColor: '#fa755a'
          }
        }
      }
    },
    methods: {
      ...mapActions({
        me: 'auth/me'
      }),
      init () {
        if (this.hasPaymentMethod) {
          this.showExistingCard()
        } else {
          this.showCardInput()
        }

        this.includeStripe('js.stripe.com/v3/', function(){
            this.configureStripe()
            this.loading = false
        }.bind(this) )
      },
      showCardInput () {
        // console.log('showing card input')
        this.existingCardVisible = false
        this.cardInputVisible = true
        this.getStripeSetupIntent()
      },
      showExistingCard () {
        // console.log('showing existing card')
        this.cardInputVisible = false
        this.existingCardVisible = true
      },
      async getStripeSetupIntent () {
        let response = await StripeSetupIntentService.new()
        this.stripeSetupIntent = response.data
        // console.log('stripeSetupIntent', this.stripeSetupIntent)
      },
      includeStripe( URL, callback ){
        let documentTag = document, tag = 'script',
            object = documentTag.createElement(tag),
            scriptTag = documentTag.getElementsByTagName(tag)[0]
        object.src = '//' + URL
        if (callback) { object.addEventListener('load', function (e) { callback(null, e); }, false) }
        scriptTag.parentNode.insertBefore(object, scriptTag)
      },
      configureStripe(){
        this.stripe = Stripe( this.stripeAPIToken );

        this.elements = this.stripe.elements();
        this.card = this.elements.create('card', { style: this.style, hidePostalCode: true });

        this.card.mount('#card-element');
      },
      submit() {
        this.loading = true
        this.addPaymentStatus = 1

        this.stripe.confirmCardSetup(
            this.stripeSetupIntent.client_secret, {
                payment_method: {
                    card: this.card,
                    billing_details: {
                        name: this.name
                    }
                }
            }
        ).then(function(result) {
            if (result.error) {
                this.addPaymentStatus = 3;
                this.addPaymentStatusError = result.error.message
            } else {
                this.savePaymentMethod( result.setupIntent.payment_method );
                this.addPaymentStatus = 2;
                this.card.clear()
                this.name = '';
            }
        }.bind(this));
      },
      async savePaymentMethod (payment_method_id) {
        try {
        let result = await PaymentMethodService.store(payment_method_id)
        this.showExistingCard()
        this.me()
        this.showNotify('Payment info saved.', 'success', 'Success')
        this.loading = false
        console.log(result)
        } catch (err) {
          this.showNotify('Payment info failed to save.', 'danger', 'Failed')
          this.loading = false
        }
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }

</script>
<style>

</style>
