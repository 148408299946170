var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate.apply(null, arguments)}}},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-sm-10"},[_c('h3',{staticClass:"info-text"},[_vm._v("Register")]),_c('p',[_vm._v(" If you have an invite code you are welcome to register now, otherwise please "),_c('a',{attrs:{"href":"http://localhost:8000/login#/login"}},[_vm._v("join the waiting list")]),_vm._v(". ")])]),_c('div',{staticClass:"col-sm-5"},[_c('ValidationProvider',{attrs:{"name":"inviteCode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Invite code field is required': null,"hasSuccess":passed,"name":"inviteCode","addon-left-icon":"nc-icon nc-lock-circle-open","placeholder":"Invite code..."},on:{"blur":_vm.checkInviteCode},model:{value:(_vm.form.inviteCode),callback:function ($$v) {_vm.$set(_vm.form, "inviteCode", $$v)},expression:"form.inviteCode"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"text","error":failed ? 'The Name field is required': null,"hasSuccess":passed,"name":"name","addon-left-icon":"nc-icon nc-single-02","placeholder":"Name..."},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"email","error":failed ? 'The Email field is required': null,"hasSuccess":passed,"name":"email","addon-left-icon":"nc-icon nc-email-85","placeholder":"Email..."},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}])})],1),_c('div',{staticClass:"col-sm-5"},[_c('ValidationProvider',{attrs:{"name":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'Password must contain at least 6 chars, 1 capital, 1 lowercase, 1 number': null,"hasSuccess":passed,"name":"password","addon-left-icon":"nc-icon nc-key-25","placeholder":"Password..."},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"password_confirmation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passed = ref.passed;
var failed = ref.failed;
return [_c('fg-input',{attrs:{"type":"password","error":failed ? 'Password must contain at least 6 chars, 1 capital, 1 lowercase, 1 number': null,"hasSuccess":passed,"name":"password_confirmation","addon-left-icon":"nc-icon nc-key-25","placeholder":"Confirm Password..."},model:{value:(_vm.form.password_confirmation),callback:function ($$v) {_vm.$set(_vm.form, "password_confirmation", $$v)},expression:"form.password_confirmation"}})]}}])}),_c('p-checkbox',{staticClass:"text-left",attrs:{"name":"terms"},model:{value:(_vm.form.acceptTerms),callback:function ($$v) {_vm.$set(_vm.form, "acceptTerms", $$v)},expression:"form.acceptTerms"}},[_vm._v(" I agree to the "),_c('a',{attrs:{"href":"https://www.authentic.net/terms","target":"_blank"}},[_vm._v("terms and conditions")]),_vm._v(". ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }