<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-xs-5">
            <div class="regcard-img-container">
              <img v-if="registration.product.product_type.img" :src="registration.product.product_type.img" style="max-width: 140px; max-height: 120px;">
              <span v-if="!registration.product.product_type.img">No Image</span>
            </div>
          </div>
          <div class="col-xs-7">
            <p><strong>{{limitChars(registration.product.product_type.name)}}</strong></p>
            {{registration.first_name}} {{registration.last_name}}<br />
            {{registration.email}}<br>
            {{addressRegion(registration)}}<br>
            {{registration.country}}&nbsp;
          </div>
        </div>
      </div>
      <div class="card-footer">
        <hr/>
        <i v-if="false" class="nc-icon nc-watch-time"></i> {{friendlyDate(registration.created_at)}}
        <p-button type="primary" round size="sm" class="pull-right" @click.prevent="regDetailsPopup(registration)">View Details</p-button>
      </div>
    </div>
  </div>
</template>
<script>
  import { NotifyMixin } from 'src/mixins/notify.mixin'
  import moment from 'moment'
  import swal from 'sweetalert2'

  export default{
    props: {
      registration: {
        type: Object,
        required: true
      }
    },
    mixins: [NotifyMixin],
    data () {
      return {
        initialized: false
      }
    },
    methods: {
      init () {
        // 
      },
      friendlyDate (date) {
        return moment(date).format('LL')
      },
      addressRegion (regRecord) {
        let region = ''
        if (regRecord.city) region += regRecord.city
        if (regRecord.state) {
          if (region === '') region += regRecord.state
          else region += ', ' + regRecord.state
        }
        return region
      },
      limitChars (x) {
        if (x.length > 20) return x.substring(0, 17) + '...'
        return x
      },
      async regDetailsPopup (regRecord) {
        let prodDetailsHtml = `<b>Registration Date:</b> ${this.friendlyDate(regRecord.created_at)}`
        for (let i = 0; i < regRecord.product.option_assignments.length; i++) {
          let optionName = regRecord.product.option_assignments[i].option.name
          let optionValue = regRecord.product.option_assignments[i].option_value.name
          prodDetailsHtml += `<br><b>${optionName}:</b> ${optionValue}`
        }
        if (regRecord.product.upc) prodDetailsHtml += `<br><b>UPC:</b> ${regRecord.product.upc}`
        if (regRecord.product.sku) prodDetailsHtml += `<br><b>SKU:</b> ${regRecord.product.sku}`
        prodDetailsHtml += '<br>'
        if (regRecord.first_name) prodDetailsHtml += `<br><b>First Name:</b> ${regRecord.first_name}`
        if (regRecord.last_name) prodDetailsHtml += `<br><b>Last Name:</b> ${regRecord.last_name}`
        if (regRecord.email) prodDetailsHtml += `<br><b>Email:</b> ${regRecord.email}`
        if (regRecord.address1) prodDetailsHtml += `<br><b>Address:</b> ${regRecord.address1}`
        if (regRecord.address2) prodDetailsHtml += `<br><b>Address 2:</b> ${regRecord.address2}`
        if (regRecord.city) prodDetailsHtml += `<br><b>City:</b> ${regRecord.city}`
        if (regRecord.state) prodDetailsHtml += `<br><b>State:</b> ${regRecord.state}`
        if (regRecord.zip) prodDetailsHtml += `<br><b>Zip:</b> ${regRecord.zip}`
        if (regRecord.country) prodDetailsHtml += `<br><b>Country:</b> ${regRecord.country}`
        prodDetailsHtml += `<br><b>Authentic.net Code Created:</b> ${this.friendlyDate(regRecord.auth_code.created_at)}`
        swal.fire({
          title: `<strong>Registration Details</strong>`,
          html:
            `<b>Product:</b> ${regRecord.product.product_type.name}<br>` +
            prodDetailsHtml,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Close'
        })
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }
</script>
<style>
.regcard-img-container {
  max-width: 140px;
  max-height: 140px;
  margin: auto 15px;
  text-align: center;
}
.regcard-img-container img {
  max-width: 140px;
  max-height: 120px;
}
</style>
