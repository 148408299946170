<template>
  <div class="row">
    <div class="col-lg-4 col-md-12">
      <user-card>

      </user-card>
    </div>
    <div class="col-lg-8 col-md-12">
      <edit-profile-form>

      </edit-profile-form>
    </div>
    <div v-if="hasActiveSubscription" class="col-lg-7 col-md-12">
      <plan-details></plan-details>
    </div>
    <div class="col-lg-5 col-md-12">
      <payment-method></payment-method>
    </div>
  </div>
</template>
<script>
  import EditProfileForm from './Components/EditProfileForm.vue'
  import UserCard from './Components/UserCard.vue'
  import PlanDetails from './Components/PlanDetails.vue'
  import PaymentMethod from './Components/PaymentMethod.vue'
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      hasActiveSubscription () {
        return this.user.subscription != null
      }
    },
    components: {
      EditProfileForm,
      UserCard,
      PlanDetails,
      PaymentMethod
    },
    methods: {
      async init () {
        // console.log(this.user, this.user.subscription)
      },
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }

</script>
<style>

</style>
