<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-md-12">

        <card v-if="openBatches" style="min-height: 190px;">
            <h4 slot="header" class="card-title">
              Generate New Authentic.net Codes
            </h4>
            <p class="text-danger">Please print and close any open batches before generating another one.</p>
        </card> <!-- end card -->

        <card v-if="!openBatches && monthlyMaxReached" style="min-height: 190px;">
          <h4 slot="header" class="card-title">
            Generate New Authentic.net Codes
          </h4>
          <p class="text-danger">Please upgrade your Authentic.net plan if you wish to generate more codes this month.</p>
        </card> <!-- end card -->

        <card v-if="isInitialized && !selectedProductId && !createBlankCodes && !openBatches && !monthlyMaxReached" style="min-height: 190px;">
            <h4 slot="header" class="card-title">
              Generate New Authentic.net Codes
            </h4>
            <p class="text-danger">Please <router-link :to="{ name: 'Products' }"><p-button type="primary" round size="sm">Select A Product</p-button></router-link> that you want to protect with Authentic.net labels.</p>
            <p class="text-danger" style="display: none;">Or, create <p-button type="primary" round size="sm" @click="toggleCreateBlankCodes()">Blank Auth Codes</p-button> that you can assign to products later.</p>
        </card> <!-- end card -->

        <card v-if="isInitialized && (selectedProductId || createBlankCodes) && !openBatches && !monthlyMaxReached" style="min-height: 190px;">
            <h4 slot="header" class="card-title">
              Generate New Authentic.net Codes
            </h4>
            <div v-if="selectedProductId" class="row">
              <div class="col-sm-3">
                <img v-if="product.product_type.img" :src="product.product_type.img" :alt="product.product_type.name">
              </div>
              <div class="col-sm-9">
                <div>
                  <strong>SKU:</strong> {{product.sku}}
                </div>
                <div v-for="(option_assignment, opt_index) in product.option_assignments" :key="product.id + '-' + option_assignment.option.name">
                  <strong>{{ option_assignment.option.name }}:</strong> {{ option_assignment.option_value.name }}
                </div>
                <div v-for="(fnsku, fnsku_index) in product.fnsku_assignments" :key="product.id + '-fnsku-' + fnsku_index">
                  <strong>{{ fnsku.marketplace }}:</strong> {{ fnsku.fnsku }}
                </div>
              </div>
            </div>
            <div v-if="!createBlankCodes" class="row">
              <label class="col-md-3 col-form-label">Retailer</label>
              <div class="col-md-9">
                <el-select class="select-primary" style="margin-bottom: 10px;"
                          size="large"
                          placeholder="Retailer *optional"
                          v-model="retailerSelect.retailer_id">
                  <el-option v-for="option in retailerSelect.retailers"
                            class="select-danger"
                            :value="option.id"
                            :label="option.name"
                            :key="option.name">
                  </el-option>
                </el-select>
              </div>
              
              <label class="col-md-3 col-form-label">Batch / Lot Num</label>
              <div class="col-md-9">
                <fg-input v-model="model.lotNum" placeholder="*optional"></fg-input>
              </div>
            </div>
            <div class="row">
              <label class="col-md-3 col-form-label">Quantity</label>
              <div class="col-md-9">
                <fg-input v-model="model.qty"></fg-input>
              </div>
            </div>

            <div slot="footer" class="row">
              <div class="col-md-12">

                <p-button v-if="!loading" type="primary" round @click.prevent="validateRequestCodes()">Generate</p-button>
                <p-button v-if="loading" type="primary" round disabled="disabled">
                  <i class="fa fa-circle-o-notch fa-spin"></i>
                  Generate
                </p-button>

              </div>
            </div>
        </card> <!-- end card -->

      </div>
      <div class="col-lg-6 col-md-12">
        <card style="min-height: 190px;">
            <h4 slot="header" class="card-title">
              Printer Settings
            </h4>
            <div class="row">
              <label class="col-md-3 col-form-label">Printer</label>
              <div class="col-md-9">
                <el-select class="select-primary"
                          size="large"
                          style="width: 100%;"
                          placeholder="Select Printer"
                          v-model="dymoPrinterName">
                  <el-option v-for="option in dymoPrinters"
                            class="select-primary"
                            :value="option.name"
                            :label="option.name"
                            :key="option.name">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row" style="margin-top: 10px;">
              <label class="col-md-3 col-form-label">Label Size</label>
              <div class="col-md-9">
                <el-select class="select-primary"
                          size="large"
                          style="width: 100%;"
                          placeholder="Select Label Size"
                          v-model="dymoLabelSize">
                  <el-option v-for="option in dymoLabelSizes"
                            class="select-primary"
                            :value="option"
                            :label="dymoLabelSizeMap.get(option)"
                            :key="option">
                  </el-option>
                </el-select>
              </div>
            </div>
        </card> <!-- end card -->
      </div>
    </div>
    <auth-code-batches-table :user="user" v-if="isInitialized" :key="addedBatches" :showComplete="showComplete" :pathDymoAuthLabel="pathDymoAuthLabel" :pathDymoFbaLabel="pathDymoFbaLabel" :dymoPrinterName="dymoPrinterName" @completed="batchCompleted"></auth-code-batches-table>
    <div v-if="false" class="row">
      <div class="col-md-4 pull-right">
        <div style="float: right;">
          <p class="category">Show Complete/Closed</p>
          <p-switch v-model="showComplete" type="primary" on-text="ON" off-text="OFF"></p-switch>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { mapGetters } from 'vuex'
  import AuthCodeBatchesTable from 'src/components/Dashboard/Views/Account/Components/AuthCodeBatchesTable.vue'
  import PSwitch from 'src/components/UIComponents/Switch.vue'
  import axios from 'axios'
  import swal from 'sweetalert2'
  import moment from 'moment'
  import { NotifyMixin } from 'src/mixins/notify.mixin'

  import SalesChannelService from 'src/services/salesChannel.service'
  import ProductService from 'src/services/product.service'
  import AuthCodeBatchService from 'src/services/authCodeBatch.service'

  export default{
    components: {
      PSwitch,
      AuthCodeBatchesTable
    },
    mixins: [NotifyMixin],
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
      monthlyMaxReached () {
        return false
      },
      isInitialized () {
        return this.initialized
      }
    },
    data () {
      return {
        model: {
          qty: '',
          lotNum: '',
          accountId: '',
          productId: '',
          status: 'Requested',
          qtyCreated: 0,
          qtyValidated: 0,
          qtyFraudReports: 0
        },
        loading: true,
        initialized: false,
        modelValidations: {
          lotNum: {
            required: false
          },
          qty: {
            required: true,
            integer: true,
            min_value: 1,
            max_value: this.maxPerBatch()
          }
        },
        authCodeBatches: [],
        productSelect: {
          product_id: '',
          products: []
        },
        selectedProductId: '',
        createBlankCodes: false,
        product: {
          id: null,
          product_type: {},
          option_assignments: [],
          fnsku_assignments: []
        },
        brands: [],
        products: [],
        retailerSelect: {
          retailer_id: '',
          retailers: []
        },
        retailers: [],
        getAuthCodeBatchesInterval: 0,
        labelImgSrc: '',
        label: null,
        dymoPrinters: [],
        dymoPrinterName: '',
        dymoLabelSizes: ['30334'],
        dymoLabelSize: '30334',
        dymoLabelSizeMap: new Map(),
        dymoLabelXml: '',
        pathDymoAuthLabel: '',
        pathDymoFbaLabel: '',
        addedBatches: 0,
        authBarcodeBaseUrl: process.env.AUTH_BARCODE_BASE_URL,
        showComplete: false,
        openBatches: true
      }
    },
    methods: {
      async init () {
        this.dymoLabelSizeMap.set('30334', '30334 - 1.25"x2.25"')
        this.dymoLabelSizeMap.set('5160', '5160 - 1"x2.63"')
        // this.getBrandsAndProductsAndBatches()
        this.getSalesChannels()
        // this.loadExternalJs()
        Vue.loadScript("js/dymo.js")
        .then(() => {
          this.getDymoPrinters()
        })
        .catch(() => {
          console.log('failed loading')
        })
        // setTimeout(this.getDymoPrinters, 3000)
        this.pathDymoFbaLabel = `static/dymoLayouts/30334-fba-fnsku.xml`
        this.getLabelXml('30334')
        this.selectedProductId = this.$route.params.productId
        if (this.selectedProductId) await this.loadProduct()
        await this.checkOpenBatches()
        this.initialized = true
      },
      toggleCreateBlankCodes () {
        this.createBlankCodes = !this.createBlankCodes
      },
      loadExternalJs () {
        // let dymoScript = document.createElement('script')
        // dymoScript.setAttribute('src', '/static/js/dymo.js')
        // document.head.appendChild(dymoScript)
      },
      async checkOpenBatches () {
        console.log('checking open batches')
        let response = await AuthCodeBatchService.indexNotComplete(this.user.options.active_brand_id)
        this.openBatches = response.data.batches.length > 0
      },
      maxPerBatch () {
        return 10000
        // let max = this.accountData.authDetails.account.data.planConstraints.maxCodesPerMonth
        // if (!this.accountData.authDetails.account.data.lastBatchCreatedAt) return max
        // let lastBatchCreatedAt = this.accountData.authDetails.account.data.lastBatchCreatedAt.toDate()
        // // if not the same month... ie it's next month and no codes have been made yet
        // if (!moment().isSame(lastBatchCreatedAt, 'year') || !moment().isSame(lastBatchCreatedAt, 'month')) return max
        // let used = this.accountData.authDetails.account.data.planUsageQtyCodesThisMonth
        // let amount = max - used
        // amount = Math.max(amount, 0)
        // amount = Math.min(amount, 1000)
        // return amount
      },
      async loadProduct () {
        let response = await ProductService.get(this.user.options.active_brand_id, this.selectedProductId)
        console.log('response', response)
        this.product = response.data
        // this.product.data.image = await this.prodImgUrl(prodDoc.id, prodDoc.data(), '140x140')
      },
      // getBrandsAndProductsAndBatches () {
      //   db.collection(`brands`).where('accountId', '==', this.user.accountId).get().then(qs => {
      //     qs.forEach(doc => {
      //       let brand = doc.data()
      //       brand.id = doc.id
      //       this.brands.push(brand)
      //       this.getBrandProducts(brand.id, brand.name)
      //       // this.getBrandAuthCodeBatches(brand.id)
      //     })
      //     // this.getAuthCodeBatches()
      //   })
      // },
      // getBrandProducts (brandId, brandName) {
      //   db.collection(`brands/${brandId}/products`).get().then(qs => {
      //     qs.forEach(doc => {
      //       let product = doc.data()
      //       product.id = doc.id
      //       product.brandName = brandName
      //       product.brandId = brandId
      //       // console.log(product)
      //       this.products.push(product)
      //     })
      //   })
      // },
      async getSalesChannels () {
        let response = await SalesChannelService.index(this.user.options.active_brand_id)
        this.retailerSelect.retailers = response.data
      },
      getDymoPrinters () {
        this.loading = false
        try {
          this.dymoPrinterName = 'Avery Labels (PDF)'
          dymo.label.framework.getPrintersAsync().then(result => {
            console.log('Printers:')
            console.log(result)
            this.dymoPrinters = result
            this.dymoPrinterName = result[0].name
            console.log(this.dymoPrinterName)
            this.dymoPrinters.push({name: 'Avery Labels (PDF)'})
          })
        } catch (err) {
          console.log('failed to sync dymo')
          this.dymoPrinters = [{name: 'Avery Labels (PDF)'}]
          this.dymoPrinterName = 'Avery Labels (PDF)'
        }
      },
      getLabelXml (dymoSizeCode) {
        if (false && this.user.accountId === 'D3zXQmPabbnZY5wEo8rV') {
        // if (this.product.product_type.name && this.product.product_type.name.toLowerCase().includes('kegelbell')) {
          this.pathDymoAuthLabel = `static/dymoLayouts/${dymoSizeCode}-kegelbell-3.xml`
        } else {
          this.pathDymoAuthLabel = `static/dymoLayouts/${dymoSizeCode}.xml`
        }
        // axios.get(this.pathDymoAuthLabel).then(response => {
        //   this.dymoLabelXml = response.data
        // })
      },
      // getAuthCodeBatches () {
      //   this.openBatches = true
      //   db.collection(`brands/${this.accountData.activeBrand.id}/authCodeBatches`).orderBy('createdAt', 'desc').limit(5)
      //   .onSnapshot(snapshot => {
      //     snapshot.docChanges().forEach(change => {
      //       if (change.type === 'added') {
      //         let doc = change.doc
      //         let data = doc.data()
      //         data.id = doc.id
      //         this.applyBatchProductInfo(data)
      //       }
      //       if (change.type === 'modified') {
      //         let doc = change.doc
      //         let data = doc.data()
      //         data.id = doc.id
      //         let arr = []
      //         for (var i in this.authCodeBatches) {
      //           if (this.authCodeBatches[i].id === data.id) {
      //             let productName = this.authCodeBatches[i].productName
      //             let productImageUrl = this.authCodeBatches[i].productImageUrl
      //             this.authCodeBatches[i] = data
      //             this.authCodeBatches[i].productName = productName
      //             this.authCodeBatches[i].productImageUrl = productImageUrl
      //           }
      //           arr.push(this.authCodeBatches[i])
      //         }
      //         this.authCodeBatches = arr
      //       }
      //     })
      //   })
      // },
      // applyBatchProductInfo (batchObject) {
      //   db.collection(`brands/${this.accountData.activeBrand.id}/products`).doc(batchObject.productId).get().then(doc => {
      //     if (doc.exists) {
      //       batchObject.productName = doc.data().name
      //       batchObject.productImageUrl = doc.data().imageUrl
      //       batchObject.amazonComFnsku = doc.data().amazonComFnsku
      //       batchObject.loading = false
      //       this.authCodeBatches.unshift(batchObject)
      //     } else {
      //       // prod doesn't exits. should never happen.
      //     }
      //   })
      // },
      friendlyDateTime (timestamp) {
        if (timestamp === undefined || timestamp === '') return 'Never'
        else return moment(timestamp.seconds * 1000).format('LLL')
      },
      formatLastPrinted (timestamp) {
        // sfd = new SimpleDateFormat('MM-dd-YYYY')
        // return sfd.format(new Date(timestamp))
        // return timestamp.seconds
        if (timestamp === undefined || timestamp === '') return 'Never'
        else return moment(timestamp.seconds * 1000).format('l')
      },
      async getFbaLabelPrintQty (batchQty) {
        try {
          let qtyResult = await swal.fire({
            title: 'Quantity',
            text: `This batch consists of ${batchQty} units, but you can change the quantity of Amazon FBA labels to print below:`,
            type: 'question',
            input: 'text',
            showCancelButton: true,
            confirmButtonClass: 'btn btn-success btn-fill',
            cancelButtonClass: 'btn btn-danger btn-fill',
            buttonsStyling: false,
            inputValue: batchQty
          })
          if (qtyResult.value) {
            let printQty = batchQty
            let isInt = /^[0-9]+$/
            if (isInt.test(qtyResult.value)) {
              printQty = Number(qtyResult.value)
              if (printQty >= 1 && printQty <= 500) return printQty
            }
            await swal.fire({
              title: 'Invalid Value',
              text: 'Sorry, the value must be an integer between 1 and 500.',
              type: 'error',
              confirmButtonClass: 'btn btn-info btn-fill',
              buttonsStyling: false
            })
            return this.getFbaLabelPrintQty(batchQty)
          }// else if (result.dismiss === Swal.DismissReason.cancel) return false
        } catch (err) {
          this.showNotify('Print failed to to cancellation or error.', 'danger', 'Cancelled')
        }
      },
      // async printBatchFbaLabels (authCodeBatch) {
      //   authCodeBatch.loading = true

      //   let printQty = await this.getFbaLabelPrintQty(authCodeBatch.qty)
      //   if (!printQty) return false

      //   let response = await axios.get(this.pathDymoFbaLabel)
      //   let labelXml = response.data
      //   let productDoc = await db.collection(`brands/${authCodeBatch.brandId}/products`).doc(authCodeBatch.productId).get()
      //   let label = dymo.label.framework.openLabelXml(labelXml)
      //   let labelSet = new dymo.label.framework.LabelSetBuilder()
      //   let labelParams = dymo.label.framework.createLabelWriterPrintParamsXml({printQuality: dymo.label.framework.LabelWriterPrintQuality.Text})

      //   var records = []
      //   for (var i = 0; i < printQty; i++) {
      //     records[i] = labelSet.addRecord()
      //     records[i].setText('BARCODE', productDoc.data().amazonComFnsku)
      //     records[i].setText('TEXT_PRODUCT', productDoc.data().name)
      //   }

      //   await label.printAsync(this.dymoPrinterName, labelParams, labelSet)
      //   authCodeBatch.loading = false
      //   swal.fire({
      //     title: `Print Job Submitted`,
      //     text: 'Apply these labels per Amazon\'s instructions.',
      //     buttonsStyling: false,
      //     confirmButtonClass: 'btn btn-success btn-fill',
      //     type: 'success'
      //   })
      // },
      // confirmPrint (authCodeBatch) {
      //   swal.fire({
      //     title: 'Did this batch print correctly?',
      //     text: `For security your raw Authentic.net codes for this batch will be removed from our system and printing this batch will be permanently disabled.`,
      //     type: 'warning',
      //     showCancelButton: true,
      //     confirmButtonClass: 'btn btn-success btn-fill',
      //     cancelButtonClass: 'btn btn-danger btn-fill',
      //     confirmButtonText: 'Yes, disable printing!',
      //     buttonsStyling: false
      //   }).then(() => {
      //     db.collection(`brands/${this.accountData.activeBrand.id}/authCodeBatches`).doc(authCodeBatch.id).update({printConfirmed: true, status: 'Complete'}).then(result => {
      //       swal.fire({
      //         title: 'Confirmed!',
      //         text: 'Printing for this batch has been confirmed and your Authentic.net codes have been fully secured.',
      //         type: 'success',
      //         confirmButtonClass: 'btn btn-success btn-fill',
      //         buttonsStyling: false
      //       })
      //     })
      //   })
      // },
      makeAuthCodes () {
        AuthCodeBatchService.create(
            this.user.options.active_brand_id,
            this.model.qty,
            this.product.id,
            this.retailerSelect.retailer_id,
            this.model.lotNum
          ).then((response) => {
            this.loading = false
            this.openBatches = true
          }).catch((err) => {
            if (err.response.data.message) this.showNotify(err.response.data.message, 'danger', 'Failed')
            else this.showNotify(err, 'danger', 'Failed')
            this.loading = false
          })
      },
      // async makeAuthCodes () {
      //   // this.model.products[this.productSelect.product_id] = true
      //   this.model.accountId = this.user.accountId
      //   this.model.productId = this.selectedProductId
      //   this.model.status = 'Requested'
      //   this.model.qtyCreated = 0
      //   this.model.createdAt = firebase.firestore.FieldValue.serverTimestamp()
      //   if (this.retailerSelect.retailer_id !== '') {
      //     this.model.retailerId = this.retailerSelect.retailer_id
      //   } else {
      //     this.model.retailerId = ''
      //   }
      //   let prodDoc = await db.collection(`brands/${this.accountData.activeBrand.id}/products`).doc(this.selectedProductId).get()
      //   let product = prodDoc.data()
      //   product.id = prodDoc.id
      //   this.model.brandId = product.brandId
      //   console.log(this.model)
      //   db.collection(`brands/${this.accountData.activeBrand.id}/authCodeBatches`).add(this.model).then(response => {
      //     this.addedBatches++
      //     this.loading = false
      //     // setTimeout(() => {
      //     //   EventBus.$emit('reload-account-data-silent')
      //     // }, 1000)
      //     // EventBus.$emit('reload-account-data-silent')
      //     this.openBatches = true
      //   })
      //   .catch(err => {
      //     swal.fire({
      //       title: 'Error',
      //       text: err,
      //       type: 'error',
      //       confirmButtonClass: 'btn btn-info btn-fill',
      //       buttonsStyling: false
      //     })
      //     this.loading = false
      //   })
      // },
      // getLabelData (authCodeDoc) {
      //   let data = {
      //     code: prettyV2Code('0002-' + authCodeDoc.data().code)
      //   }
      //   records[i] = labelSet.addRecord()
      //   records[i].setText('BARCODE', `URL:https://scan.authentic.net/${code}`)
      //   records[i].setText('TEXT_PRODUCT', doc.data().productName)
      //   records[i].setText('TEXT_CODE', code)
      // },
      // async consoleLogAuthCodeBatch (authCodeBatch) {
      //   console.log(authCodeBatch)
      //   let userToken = await firebase.auth().currentUser.getIdToken()
      //   let data = {
      //     userToken: userToken,
      //     authCodeBatchId: authCodeBatch.id
      //   }
      //   let getAccountAuthCodesByBatchId = firebase.functions().httpsCallable('getAccountAuthCodesByBatchId')
      //   getAccountAuthCodesByBatchId(data)
      //   .then(result => {
      //     // this.showNotify('test error message.', 'error', 'Error')
      //     console.log(authCodeBatch)
      //     console.log(result)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //     // this.showNotify('Error fetching auth codes.', 'error', 'Error')
      //   })
      // },
      // async printAuthCodeBatch (authCodeBatch) {
      //   try {
      //     authCodeBatch.status = 'Printing'
      //     await db.collection(`brands/${this.accountData.activeBrand.id}/authCodeBatches`).doc(authCodeBatch.id).update({status: 'Printed', printedAt: firebase.firestore.FieldValue.serverTimestamp()})
      //     swal.fire({
      //       title: `Print Job Submitting`,
      //       text: 'Allow at least 10 seconds for the Dymo to start printing. Be sure to print and use these labels 1 time only then close this batch. If you re-print then be sure to destroy any labels that were printed previously in order to avoid duplicates.',
      //       buttonsStyling: false,
      //       confirmButtonClass: 'btn btn-success btn-fill',
      //       type: 'success'
      //     })

      //     let userToken = await firebase.auth().currentUser.getIdToken()
      //     let data = {
      //       userToken: userToken,
      //       authCodeBatchId: authCodeBatch.id
      //     }
      //     let getAccountAuthCodesByBatchId = firebase.functions().httpsCallable('getAccountAuthCodesByBatchId')
      //     let result = await getAccountAuthCodesByBatchId(data)

      //     // this.showNotify('test error message.', 'error', 'Error')
      //     console.log(result)
      //     var label = dymo.label.framework.openLabelXml(this.dymoLabelXml)
      //     var labelSet = new dymo.label.framework.LabelSetBuilder()
      //     var labelParams = dymo.label.framework.createLabelWriterPrintParamsXml({printQuality: dymo.label.framework.LabelWriterPrintQuality.Text})
      //     var records = []
      //     let i = 0
      //     for (const authCode of result.data.authCodes) {
      //       // console.log(doc.data().code)
      //       let code = this.prettyV2Code(authCode.data.code)
      //       records[i] = labelSet.addRecord()
      //       records[i].setText('BARCODE', `URL:${this.authBarcodeBaseUrl}${code}`)
      //       if (this.user.accountId !== 'ABHVeLLQ4zCxNUiqOfyG') {
      //         records[i].setText('TEXT_PRODUCT', authCodeBatch.productName)
      //         records[i].setText('TEXT_CODE', code)
      //       }
      //       // records[i].setText('BARCODE', `URL:https://appbeta.authentic.net/authenticate?code=b452a838-5dbb-4217-a075-57957b8d2da9`)
      //       i++
      //     }
      //     console.log(records)
      //     await label.printAsync(this.dymoPrinterName, labelParams, labelSet)
      //   } catch (err) {
      //     console.log(err)
      //     swal.fire({
      //       title: 'Error',
      //       text: 'An error took place while attempting to print. If all labels printed then the error can be ignored and the batch should be closed. If you must re-print then please destroy all labels already printed to avoid duplicates.',
      //       type: 'error',
      //       confirmButtonClass: 'btn btn-info btn-fill',
      //       buttonsStyling: false
      //     })
      //   }
      // },
      // printAuthCodeBatchBack (authCodeBatch) {
      //   authCodeBatch.status = 'Printing'
      //   db.collection('authCodes').where('authCodeBatchId', '==', authCodeBatch.id).get()
      //   .then(snapshot => {
      //     var label = dymo.label.framework.openLabelXml(this.dymoLabelXml)
      //     var labelSet = new dymo.label.framework.LabelSetBuilder()
      //     var labelParams = dymo.label.framework.createLabelWriterPrintParamsXml({printQuality: dymo.label.framework.LabelWriterPrintQuality.Text})
      //     var records = []
      //     let i = 0
      //     snapshot.forEach(doc => {
      //       // console.log(doc.data().code)
      //       let code = this.prettyV2Code(doc.data().code)
      //       records[i] = labelSet.addRecord()
      //       records[i].setText('BARCODE', `URL:${this.authBarcodeBaseUrl}${code}`)
      //       records[i].setText('TEXT_PRODUCT', authCodeBatch.productName)
      //       records[i].setText('TEXT_CODE', code)
      //       // records[i].setText('BARCODE', `URL:https://appbeta.authentic.net/authenticate?code=b452a838-5dbb-4217-a075-57957b8d2da9`)
      //       i++
      //     })
      //     console.log(records)
      //     label.printAsync(this.dymoPrinterName, labelParams, labelSet).then(result => {
      //       db.collection(`accounts/${this.user.accountId}/authCodeBatches`).doc(authCodeBatch.id).update({status: 'Printed', printedAt: firebase.firestore.FieldValue.serverTimestamp()}).then(result => {
      //         // authCodeBatch.status = 'Processed'
      //         swal.fire({
      //           title: `Print Job Submitted`,
      //           text: 'Be sure to use these labels 1 time only then Confirm Print. If you re-print then be sure to destroy any labels that were printed previously.',
      //           buttonsStyling: false,
      //           confirmButtonClass: 'btn btn-success btn-fill',
      //           type: 'success'
      //         })
      //       })
      //     })
      //   })
      // },
      // async downloadAuthCodeBatch (authCodeBatch) {
      //   // let url = 'https://svc.authentic.net/storage/accounts/' + authCodeBatch.account_id + '/products/' + authCodeBatch.product_id + '/auth_code_batches/' + authCodeBatch.id + '.zip'
      //   // alert(url)
      //   // window.location = url
      //   let userToken = await firebase.auth().currentUser.getIdToken()
      //   let data = {
      //     userToken: userToken,
      //     authCodeBatchId: authCodeBatch.id
      //   }
      //   let getAccountAuthCodesByBatchId = firebase.functions().httpsCallable('getAccountAuthCodesByBatchId')
      //   getAccountAuthCodesByBatchId(data)
      //   .then(result => {
      //     // this.showNotify('test error message.', 'error', 'Error')
      //     for (const authCode of result.data.authCodes) {
      //       console.log(authCode.data)
      //     }
      //   })
      //   .catch(err => {
      //     console.log(err)
      //     // this.showNotify('Error fetching auth codes.', 'error', 'Error')
      //   })
      // },
      // getLabelXml () {
      //   let labelXml = ``
      //   return labelXml
      // },
      showPrintBtn (authCodeBatch) {
        return !this.loading && (authCodeBatch.status === 'Processed' || authCodeBatch.status === 'Printed')
      },
      showPrintBtnLoading (authCodeBatch) {
        return (
          authCodeBatch.status === 'Requested' || authCodeBatch.status === 'Processing' || authCodeBatch.status === 'Printing' ||
          (this.loading && (authCodeBatch.status === 'Processed' || authCodeBatch.status === 'Printed'))
        )
      },
      showPrintConfirmBtn (authCodeBatch) {
        return !this.loading && authCodeBatch.status === 'Printed'
      },
      showPrintConfirmBtnLoading (authCodeBatch) {
        return this.loading && authCodeBatch.status === 'Printed'
      },
      makeQrcode () {
        this.code_value = this.proposed_code_value
      },
      validateRequestCodes () {
        this.loading = true
        this.makeAuthCodes()
      },
      requestCodes () {
        axios.get('https://amz.trainingmask.com/qr/' + this.model.qty, this.axios_config_2)
        .then((response) => {
          this.download_link = response.data.zip
          this.requested = true
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
        })
      },
      hasAmazonComFnsku (authCodeBatch) {
        return !!authCodeBatch.amazonComFnsku
      },
      batchCompleted () {
        this.openBatches = false
      },
      getError (fieldName) {
        return this.errors.first(fieldName)
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    },
    watch: {
      dymoPrinterName: function (newPrinterName, oldPrinterName) {
        if (newPrinterName == 'Avery Labels (PDF)') {
          this.dymoLabelSizes = ['5160']
          this.dymoLabelSize = '5160'
        } else {
          this.dymoLabelSizes = ['30334']
          this.dymoLabelSize = '30334'
        }
      }
    }
    // beforeRouteLeave: function (to, from, next) {
    //   clearInterval(this.getAuthCodeBatchesInterval)
    //   this.getAuthCodeBatchesInterval = 0
    //   next()
    // }
  }
</script>
<style>
</style>
