<template>
  <div>
    <form v-if="!validated" @submit.prevent="submit">
      <div style="text-align: center; margin-top: 40px;"><img src="/static/img/authentic_net_lt.png" width="200" alt="Authentic.net">
        <h4>Have a product with an Authentic.net code? Check your code below!</h4>
      </div>
      <div style="text-align: center; margin: 0 auto; max-width: 300px;">
        <fg-input type="text"
                  label="Authentic.net Code"
                  v-model="code">
        </fg-input>
        <p-button native-type="submit" slot="footer" type="primary" v-if="!loading" round block class="mb-3">Submit</p-button>
        <p-button native-type="submit" slot="footer" type="primary" v-if="loading" round block class="mb-3" disabled="disabled">
          <span class="btn-label">
            <i class="fa fa-circle-o-notch fa-spin"></i>
          </span>
          Submit
        </p-button>
      </div>
    </form>
    <register-code v-if="validated && !isKegelbell" :code="code" :code-data="codeData" :redirectUrl="redirectUrl"></register-code>
    <register-code-kegelbell v-if="validated && isKegelbell" :code="code" :code-data="codeData"></register-code-kegelbell>
  </div>
</template>
<script>
  import swal from 'sweetalert2'
  import AuthCodeAuthenticationService from 'src/services/authCodeAuthentication.service'
  import RegisterCode from './Components/RegisterCode.vue'
  import RegisterCodeKegelbell from './Components/RegisterCodeKegelbell.vue'

  export default {
    components: {
      RegisterCode,
      RegisterCodeKegelbell
    },
    data () {
      return {
        loading: false,
        validated: false,
        code: '',
        codeData: '',
        isKegelbell: false,
        redirectUrl: ''
      }
    },
    methods: {
      init () {
        if (this.$route.params.code) {
          this.validateParamCode(this.$route.params.code)
        } else if (this.$route.query.code) {
          this.validateParamCode(this.$route.query.code)
        }
        // console.log('code: ' + this.code)
        // if (this.model.code !== '') this.validateBeforeSubmit()
      },
      validateParamCode (codeParam) {
        let code = decodeURIComponent(codeParam)
        let moreParts = code.split('&')
        if (moreParts.length > 1) code = moreParts[0]
        moreParts = code.split('?')
        if (moreParts.length > 1) code = moreParts[0]
        this.code = code
        this.submit()
      },
      async submit () {
        try {
          let response = await AuthCodeAuthenticationService.create(this.code)
          this.codeData = response.data
          if (this.codeData.qtyRegistrations > 0) {
            let msg = 'This Authentic.net code is valid, and has been scanned ' + this.codeData.qtyValidations + ' times.'
            msg += " This Product's Registration or Warranty has been Assigned and can only be transfer by the rightful owner."
            swal.fire({
              title: 'Already Registered',
              text: msg,
              type: 'warning',
              confirmButtonClass: 'btn btn-info',
              buttonsStyling: false
            })
            .then(() => {
              // do nothing until fraud report functionality is fixed
              // this.$router.replace('/fraud-report?code=' + this.model.code)
            })
          } else {
            this.handleValidAuthCode(this.code, response.data)
          }
        } catch (e) {
          console.log(e)
          swal.fire({
            title: 'Not Authentic!',
            text: 'Sorry! The Authentic.net Code "' + this.code + '" Is Invalid.',
            type: 'error',
            confirmButtonClass: 'btn btn-info',
            buttonsStyling: false
          })
          .then(() => {
            // do nothing until fraud report functionality is fixed
            // this.$router.replace('/fraud-report?code=' + this.model.code)
          })
        }
      },
      handleValidAuthCode (code, data) {
        this.redirectUrl = data.redirectUrl;
        if (data.productType.name.toLowerCase().includes('kegelbell')) {
          console.log('is kegelbell')
          this.isKegelbell = true
        }
        let text = ''
        if (data.salesChannel !== null) {
          text = 'Congratulations! This ' + data.productType.name + ' from ' + data.salesChannel.name + ' is authentic.'
        } else {
          text = 'Congratulations! This ' + data.productType.name + ' is authentic.'
        }
        let priorValidationQty = data.qtyValidations - 1
        if (priorValidationQty > 0) {
          text += ' It has never been registered, but it has been validated ' + priorValidationQty + ' times prior.'
        }
        swal.fire({
          title: `Authentic!`,
          text: text,
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-info',
          type: 'success'
        }).then(() => {
          this.validated = true
          // console.log('redirecting with query data', data)
          // this.$router.replace({ name: 'RegisterCode', params: { code: this.model.code, codeData: data } })
          // // temporary static conditional redirect when kegelbell account product
          // if (authCode.accountId === 'D3zXQmPabbnZY5wEo8rV' && authCode.retailerId === 'T6vlUai6XeGNuH62K5kS') this.$router.replace('/kegelbell-amz?code=' + this.model.code)
          // else if (authCode.accountId === 'D3zXQmPabbnZY5wEo8rV' && authCode.retailerId === 'oKi6QqY2UGynFOXhweeD') this.$router.replace('/kegelbell-basic?code=' + this.model.code)
          // else if (authCode.accountId === 'D3zXQmPabbnZY5wEo8rV') this.$router.replace('/kegelbell?code=' + this.model.code)
          // else if (authCode.accountStatus === 'setup') this.$router.replace({ name: 'Onboarding' })
          // else this.$router.replace('/register-code?code=' + this.model.code)
        }).catch(error => {
          console.log(error)
          this.validated = true
          // this.$router.replace('/register-code?code=' + this.model.code)
          // // temporary static conditional redirect when kegelbell account product
          // if (authCode.accountId === 'D3zXQmPabbnZY5wEo8rV' && authCode.retailerId === 'T6vlUai6XeGNuH62K5kS') this.$router.replace('/kegelbell-amz?code=' + this.model.code)
          // else if (authCode.accountId === 'D3zXQmPabbnZY5wEo8rV' && authCode.retailerId === 'oKi6QqY2UGynFOXhweeD') this.$router.replace('/kegelbell-basic?code=' + this.model.code)
          // else if (authCode.accountId === 'D3zXQmPabbnZY5wEo8rV') this.$router.replace('/kegelbell?code=' + this.model.code)
          // else if (authCode.accountStatus === 'setup') this.$router.replace({ name: 'Onboarding' })
          // else this.$router.replace('/register-code?code=' + this.model.code)
        })
      }
    },
    mounted: function () {
      this.$nextTick(function () {
        this.init()
      })
    }
  }

</script>
<style>

</style>
