import Api from 'src/apis/api'

class ProductService {
  get (brandId, id) {
    return Api.get('/api/brands/' + brandId + '/products/' + id)
  }

  destroy (brandId, id) {
    return Api({
      method: 'delete',
      url: '/api/brands/' + brandId + '/products',
      data: { id: id }
    })
  }
}

export default new ProductService()
